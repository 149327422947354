import React from 'react';
import { useOutletContext } from 'react-router';

import { createColumnHelper } from '@tanstack/table-core';
import clsx from 'clsx';
import {
  Ellipsis,
  LoadingOver,
  ReactTable,
  SearchQueryParams,
  TableSkeleton,
  useLimitedPagination,
  useSearchQueryParams,
  useSorting,
} from 'components';
import {
  ActivityAuditType,
  ApplicationSearchResultItem,
  ApplicationSortableFieldEnum,
  ApplicationsListQueryVariables,
  useApplicationsListQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { useDownloadZip } from 'services';
import { EMPTY_DASH, printDate, printMoney, printPrimitive } from 'utils';

import { ApplicationBadge } from 'modules/common/ApplicationBadge/ApplicationBadge';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePageTitle } from 'modules/root/Settings';
import { notEmpty } from 'utils/helpers';

import { ApplicationAction } from './Actions/ApplicationAction';

const DEFAULT_PAGE_SIZE = 10;
const QUERY_LIMIT = 10000;

const useApplicationListQuery = (queryParams: SearchQueryParams) => {
  const [currentItems, setCurrentItems] = React.useState<
    null | ApplicationSearchResultItem[]
  >(null);
  const [total, setTotal] = React.useState(0);
  const { data, error, isLoading, refetch, isFetched, isFetching } = useQueryFetch(
    useApplicationsListQuery,
    {
      queryHookParams: queryParams as ApplicationsListQueryVariables,
      queryHookOptions: { enabled: false },
      extra: {
        auditReport: {
          activityType: queryParams.searchText
            ? ActivityAuditType.Search
            : ActivityAuditType.Read,
        },
      },
    }
  );

  React.useEffect(() => {
    if (refetch) {
      refetch();
    }
  }, [refetch, queryParams]);

  React.useEffect(() => {
    if (isFetched && data && !error) {
      const items = data?.applicationsSearch?.items?.filter(notEmpty) || [];
      setCurrentItems(items);
      setTotal(data?.applicationsSearch?.totalHits);
    }
  }, [data, error, isFetched, queryParams.searchText]);

  return {
    total,
    items: currentItems,
    isLoading: currentItems === null && isLoading,
    isUpdating: isFetching,
    error,
  };
};

export const ApplicationsTable: React.FC = () => {
  const { t } = useTranslation();
  const { download, isError: zipDownloadError } = useDownloadZip();

  const { labels, applicationsList } = useCustomisation();
  usePageTitle(labels.applications);

  const columnHelper = createColumnHelper<ApplicationSearchResultItem>();
  const columns = React.useMemo(() => {
    const headerCellsFromConfig = [];
    if (applicationsList.showFields.customerName) {
      headerCellsFromConfig.push(
        columnHelper.accessor('customerName', {
          header: labels.customer,
          id: ApplicationSortableFieldEnum.CustomerNamePlain,
          cell: ({ getValue }) => (
            <Ellipsis data-testid="customer-name" className="font-semibold max-w-[150px]">
              {printPrimitive(getValue())}
            </Ellipsis>
          ),
        })
      );
    }
    if (applicationsList.showFields.formBundleId) {
      headerCellsFromConfig.push(
        columnHelper.accessor('formBundleId', {
          header: labels.id,
          cell: ({ getValue }) => <Ellipsis className="w-24">{getValue()}</Ellipsis>,
        })
      );
    }
    if (applicationsList.showFields.lastUpdateDate) {
      headerCellsFromConfig.push(
        columnHelper.accessor('lastUpdateDate', {
          header: labels.date,
          cell: ({ getValue }) => <>{printDate(getValue())}</>,
        })
      );
    }
    if (applicationsList.showFields.requestedLoanAmount) {
      headerCellsFromConfig.push(
        columnHelper.accessor('requestedLoanAmount', {
          header: labels.loanAmount,
          cell: ({ getValue }) => <>{printMoney(getValue())}</>,
        })
      );
    }
    if (applicationsList.showFields.status) {
      headerCellsFromConfig.push(
        columnHelper.accessor('status', {
          header: labels.status,
          cell: ({ getValue }) => {
            const value = getValue();
            return value ? <ApplicationBadge status={value} /> : <>{EMPTY_DASH}</>;
          },
        })
      );
    }
    if (applicationsList.showFields.actions) {
      headerCellsFromConfig.push(
        columnHelper.accessor('id', {
          header: labels.actions,
          cell: ({ row }) => (
            <ApplicationAction application={row.original} downloadPdf={download} />
          ),
          enableSorting: false,
        })
      );
    }
    return headerCellsFromConfig;
  }, [applicationsList, columnHelper, labels, download]);

  const { searchValue } = useOutletContext<{ searchValue: string }>();
  const { handleSortChange, sorting } = useSorting();
  const { handlePageChange, isLimitReached, pagination } = useLimitedPagination(
    DEFAULT_PAGE_SIZE,
    QUERY_LIMIT
  );
  const queryParams = useSearchQueryParams({
    sorting,
    pagination,
    searchText: searchValue,
  });
  const { total, items, error, isLoading, isUpdating } =
    useApplicationListQuery(queryParams);

  React.useEffect(() => {
    handlePageChange({ ...pagination, pageIndex: 0 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [handlePageChange, searchValue]);

  const sortingMemo = React.useMemo(
    () => ({
      onChange: handleSortChange,
      state: sorting,
    }),
    [sorting, handleSortChange]
  );
  const paginationMemo = React.useMemo(
    () => ({
      onChange: handlePageChange,
      state: pagination,
      total,
    }),
    [handlePageChange, pagination, total]
  );

  return (
    <>
      <QuerySuspense
        error={error}
        isLoading={isLoading}
        noData={Boolean(!items?.length && !isLoading && !isUpdating)}
        noDataMessage={searchValue ? t('common.searchBox.noRecords') : undefined}
        loadingRenderer={() => <TableSkeleton />}
      >
        {zipDownloadError && (
          <div data-testid="download-failed-msg" className="alert">
            {t('originations.applicationsError')}
          </div>
        )}
        {searchValue && total > QUERY_LIMIT && (
          <div className={clsx('warning', 'mt-4')}>
            {t('common.searchBox.manyRecords')}
          </div>
        )}
        {items && (
          <LoadingOver show={isUpdating}>
            <ReactTable<ApplicationSearchResultItem>
              data={items}
              columns={columns}
              data-testid="applications-table"
              stickyFromTopPx={90}
              sorting={sortingMemo}
              pagination={paginationMemo}
            />
          </LoadingOver>
        )}
      </QuerySuspense>
      {isLimitReached && (
        <div className={clsx('float-right', 'alert', 'mt-4')}>
          {t('common.pagination.limitRecords')}
        </div>
      )}
    </>
  );
};
