import React from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';

import { ShrinkHeader, TabItem } from 'components';
import { useTranslation } from 'react-i18next';

import { SearchBox } from 'modules/Customers/SearchBox/SearchBox';
import { NewEvaluationButton } from 'modules/common';
import { usePaths } from 'modules/root/Settings';
import { useCustomisation } from 'modules/root/Settings';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';

import { SearchPlaceholderContext } from './searchPlaceholder.context';

export const Originations: React.FC = () => {
  const {
    permissions: {
      canCreateModifyRunEvaluations,
      canViewEvaluations,
      canViewApplications,
    },
  } = useAccess();
  const paths = usePaths();
  const navigate = useNavigate();
  const location = useLocation();
  const { labels } = useCustomisation();
  const [searchPlaceholderText, setSearchPlaceholderText] = React.useState(
    labels.evaluations
  );

  const tabs: TabItem[] = React.useMemo(() => {
    const items: TabItem[] = [];
    if (canViewEvaluations) {
      items.push({
        key: 'evaluations',
        label: labels.evaluations,
        path: paths.origination.evaluations,
        show: true,
      });
    }
    if (canViewApplications) {
      items.push({
        key: 'applications',
        label: labels.applications,
        path: paths.origination.applications,
        show: true,
      });
    }
    return items;
  }, [
    paths.origination,
    canViewApplications,
    canViewEvaluations,
    labels.applications,
    labels.evaluations,
  ]);

  React.useEffect(() => {
    if (location.pathname === paths.origination.list) {
      if (canViewEvaluations) {
        navigate(paths.origination.evaluations, { replace: true });
      } else if (canViewApplications) {
        navigate(paths.origination.applications, { replace: true });
      }
    }
  }, [canViewEvaluations, canViewApplications, navigate, paths, location.pathname]);

  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState<string>('');

  const handleSearch = React.useCallback((value: string) => {
    setSearchValue(value.trim());
  }, []);

  React.useEffect(() => {
    setSearchValue('');
  }, [searchPlaceholderText]);

  return (
    <div>
      <>
        <SearchPlaceholderContext.Provider value={{ setSearchPlaceholderText }}>
          <ShrinkHeader
            title={labels.originations}
            tabs={tabs}
            buttons={
              <div className="flex gap-4">
                {canCreateModifyRunEvaluations && (
                  <NewEvaluationButton data-testid="new-evaluation-btn" />
                )}
                <SearchBox
                  key={searchPlaceholderText}
                  onChange={handleSearch}
                  placeholder={`${t('common.searchBox.find')} ${searchPlaceholderText}`}
                />
              </div>
            }
          />
          <div className="mx-8 lg:mx-14 xl:mx-20 my-6 lg:my-10  xl:my-14">
            <Outlet context={{ searchValue }} />
            {!canViewEvaluations &&
              !canViewApplications &&
              location.pathname === paths.origination.list && (
                <div className="warning m-2" data-testid="no-permissions-message">
                  {t('auth.disallow')}
                </div>
              )}
          </div>
        </SearchPlaceholderContext.Provider>
      </>
    </div>
  );
};
