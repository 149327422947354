import React from 'react';

import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';
import { Button, FontIcon, LoadingSpinner } from 'components';
import { useTranslation } from 'react-i18next';

export interface EvaluationErrorMessageProps {
  rerunEvaluation: () => void;
}

export const EvaluationErrorMessage: React.FC<EvaluationErrorMessageProps> = ({
  rerunEvaluation,
}) => {
  const { t } = useTranslation();
  const errorMessage = t('evaluations.summary.processingFailedMSG');
  return (
    <div className="text-center" data-testid="evaluation-error">
      <h2 className="mb-10 text-xl">{t('evaluations.summary.processingFailed')}</h2>
      <div>
        <FontIcon size="3x" className="text-red-500" icon={faCircleExclamation} />
      </div>
      <div className="mt-10 mb-8">{errorMessage}</div>
      <div>
        <Button onClick={rerunEvaluation} data-testid="evaluation-try-again">
          {t('evaluations.summary.tryAgain')}
        </Button>
      </div>
    </div>
  );
};

export const EvaluationLoading: React.FC = () => {
  const { t } = useTranslation();
  return (
    <div className="text-center" data-testid="evaluation-processing">
      <h2 className="text-xl mb-2">{t('evaluations.summary.processing')}</h2>
      <div className="flex justify-center">
        <LoadingSpinner size="3x" className={'my-10'} />
      </div>
      <div>{t('evaluations.summary.processingMsg')}</div>
    </div>
  );
};
