import {
  CustomersSearchQueryVariables,
  EvaluationCollateralAccountPayload,
  SearchOrder,
  SortableFieldEnum,
} from 'generated/graphql';
import { atom } from 'jotai';

import { CustomerPage, EvaluationAccount, ManualAccountRecordErrors } from '../types';
import { isCustomerEligibleAtom } from './customer';
import { payloadAtom } from './evaluation';
import { ID_STEPS, stepsAtom } from './step';

export enum AccountSourceUI {
  None = 0,
  Acquire = 1,
  AddThirdParty = 2,
  Import = 3,
}

export enum AccountItemState {
  selectable = 'SELECTABLE',
  used = 'USED',
  unused = 'UNUSED',
}

type AccountsAtom = EvaluationAccount[] | null;
type AccountSourceAtom = AccountSourceUI | null;
type SelAccountIdsAtom = Set<string>;
export type ManualAccountErrorsAtom = Map<string, ManualAccountRecordErrors>;

export const accountsAtom = atom<AccountsAtom>(null);
export const selAccountIdsAtom = atom<SelAccountIdsAtom, [SelAccountIdsAtom], void>(
  new Set<string>(),
  (get, set, update: SelAccountIdsAtom) => {
    const isEligible = get(isCustomerEligibleAtom);
    const steps = get(stepsAtom);
    const index = steps.findIndex((step) => step.id === ID_STEPS.Accounts);
    // enable or disable next step after Accounts selection
    const updatedSteps = steps.map((step, i) => {
      if (i === index) {
        return { ...step, valid: update.size > 0 && isEligible };
      } else if (i === index + 1) {
        return { ...step, enable: update.size > 0 };
      }
      return step;
    });
    set(stepsAtom, updatedSteps);
    set(selAccountIdsAtom, update);

    // update evaluation input
    const payload = get(payloadAtom);
    const accounts = get(accountsAtom);
    set(payloadAtom, {
      ...payload,
      evaluationCollateralAccounts:
        accounts?.reduce<EvaluationCollateralAccountPayload[]>((accounts, acc) => {
          if (update.has(acc?.externalAccountId)) {
            const evalAcc = { ...acc };
            delete evalAcc['marketValue'];
            delete evalAcc['ownerDisplayName'];
            accounts.push(evalAcc);
          }
          return accounts;
        }, []) || [],
    });
  }
);

export const manualAccountsAtom = atom<AccountsAtom>(null);
export const manualAccountErrorsAtom = atom<ManualAccountErrorsAtom>(new Map());
export const selManualAccountIdsAtom = atom<SelAccountIdsAtom>(new Set<string>());

export const accountSourceAtom = atom<AccountSourceAtom>(AccountSourceUI.None);
export const chooseAccountSourceAtom = atom<AccountSourceUI>(AccountSourceUI.None);

export const evalInputUnusedAccountsAtom = atom<AccountsAtom>(null);
export const systemAccountsAtom = atom<AccountsAtom>(null);
export const evaluationInputAccountsAtom = atom<AccountsAtom, [AccountsAtom], void>(
  null,
  (get, set, update: AccountsAtom) => {
    const systemAccounts = get(systemAccountsAtom);
    const payload = get(payloadAtom);
    const usedAccIds =
      update && update.length > 0 ? update?.map((acc) => acc.externalAccountId) : [];
    const unusedAccounts =
      systemAccounts &&
      systemAccounts.filter((account) => !usedAccIds.includes(account.externalAccountId));
    set(evalInputUnusedAccountsAtom, unusedAccounts || null);
    set(evaluationInputAccountsAtom, update);
    set(selAccountIdsAtom, new Set(usedAccIds));
    set(payloadAtom, {
      ...payload,
      evaluationCollateralAccounts:
        update?.reduce<EvaluationCollateralAccountPayload[]>((accounts, acc) => {
          const systemAcc = systemAccounts?.find(
            (_acc) => _acc.externalAccountId === acc.externalAccountId
          );
          const evalAcc = { ...acc };
          if (systemAcc) {
            evalAcc.id = systemAcc.id;
            evalAcc.ownerId = systemAcc.ownerId;
          }
          delete evalAcc['marketValue'];
          delete evalAcc['collateralAccountId'];
          delete evalAcc['lastUpdateDate'];
          delete evalAcc['ownerDisplayName'];
          accounts.push(evalAcc);
          return accounts;
        }, []) || [],
    });
  }
);

export const systemAccountIdsAtom = atom<string[]>([]);

export const csvDataAtom = atom<React.ChangeEvent<HTMLInputElement> | null>(null);

export const thirdPartyCustomerIdsAtom = atom<string[]>([]);
export const thirdPartyCustomersAtom = atom<CustomerPage[]>([]);
export const canShowMoreThirdPartyCustomersAtom = atom<boolean>(false);
const thirdPartyCustomerParamsAtom = atom<CustomersSearchQueryVariables | null>(null);
export const thirdPartyCustomerSearchAtom = atom<
  CustomersSearchQueryVariables | null,
  [string, number],
  void
>(
  (get) => get(thirdPartyCustomerParamsAtom),
  (_get, set, searchText, fromIndex) => {
    set(thirdPartyCustomerParamsAtom, {
      fromIndex: fromIndex,
      numResults: 5,
      sortBy: { field: SortableFieldEnum.ServiceStatusPriority, order: SearchOrder.Desc },
      searchText: searchText,
    });
  }
);

export const showExtraStepAtom = atom<boolean>(false);
