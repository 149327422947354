import {
  CustomersSearchQueryVariables,
  SearchOrder,
  SortableFieldEnum,
} from 'generated/graphql';
import { atom } from 'jotai';

import { INELIGIBLE_STATUSES } from 'modules/common/constants/status';

import { Customer, CustomerPage } from '../types';
import { ID_STEPS, stepsAtom } from './step';

type CustomerAtom = Customer | null;

export const customersAtom = atom<CustomerPage[]>([]);

export const canShowMoreCustomersAtom = atom<boolean>(false);

export const isCustomerEligibleAtom = atom<boolean>(false);

export const selCustomerAtom = atom<CustomerAtom, [CustomerAtom], void>(
  null,
  (get, set, update: CustomerAtom) => {
    if (update) {
      const steps = get(stepsAtom);
      // enable next step after Customer selection
      const index = steps.findIndex((step) => step.id === ID_STEPS.Customer);
      const updatedSteps = steps.map((step, i) => {
        if (i === index) {
          return { ...step, valid: true };
        } else if (i === index + 1) {
          return { ...step, enable: true };
        }
        return step;
      });
      set(stepsAtom, updatedSteps);

      const isEligible = !(
        update.serviceStatus && INELIGIBLE_STATUSES.includes(update.serviceStatus)
      );
      set(isCustomerEligibleAtom, isEligible);
    }
    set(selCustomerAtom, update);
  }
);

const customerParamsAtom = atom<CustomersSearchQueryVariables | null>(null);

export const customerSearchAtom = atom<
  CustomersSearchQueryVariables | null,
  [string, number],
  void
>(
  (get) => get(customerParamsAtom),
  (_get, set, searchText, fromIndex) => {
    set(customerParamsAtom, {
      fromIndex: fromIndex,
      numResults: 5,
      sortBy: { field: SortableFieldEnum.ServiceStatusPriority, order: SearchOrder.Desc },
      searchText: searchText,
    });
  }
);
