import React from 'react';

import clsx from 'clsx';
import { Ellipsis } from 'components';

import { Customer } from 'modules/EvaluationV2/types';
import { CustomerIcon } from 'modules/common/CustomerIcon/CustomerIcon';
import { EligibilityBadge } from 'modules/common/EligibilityBadge/EligibilityBadge';
import { INELIGIBLE_STATUSES } from 'modules/common/constants/status';
import { useCustomisation } from 'modules/root/Settings';

interface CustomerItemProps {
  customer: Customer;
  selected?: boolean;
  onSelect: (customer: Customer) => void;
  icon?: React.ReactNode;
  size?: 'sm' | 'md';
}

export const CustomerItem: React.FC<CustomerItemProps> = ({
  customer,
  selected = false,
  onSelect,
  icon,
  size = 'md',
}) => {
  const { labels } = useCustomisation();
  return (
    <div
      data-testid="evaluation-customer-item"
      className={clsx('rounded-xl px-5 py-4 cursor-pointer', {
        'border-2 border-primary-600': selected,
        'border-gray-100 border hover:border-primary-200': !selected,
      })}
      role="radio"
      aria-checked={selected}
      onClick={() => onSelect(customer)}
    >
      <div className="flex items-center">
        <CustomerIcon customerName={customer.displayName} />
        <div className="ml-5">
          <div className="flex flex-col">
            <div className="max-w-[380px] font-bold">
              <Ellipsis>{customer.displayName}</Ellipsis>
            </div>
            <div className="flex gap-4 items-center">
              <div
                className={clsx({
                  'max-w-[270px]': size === 'md',
                  'max-w-[105px]': size === 'sm',
                })}
              >
                <Ellipsis>{`${labels.id.toUpperCase()} ${customer.customerId}`}</Ellipsis>
              </div>
              <div>
                <EligibilityBadge
                  eligible={
                    !(
                      customer.serviceStatus &&
                      INELIGIBLE_STATUSES.includes(customer.serviceStatus)
                    )
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="flex grow justify-end">
          {icon ? (
            <>{icon}</>
          ) : (
            <div
              className={clsx('w-5 h-5 rounded-full p-1', {
                'border border-gray-200': !selected,
                'border-2 border-primary-600': selected,
              })}
            >
              {selected && (
                <div className="bg-primary-600 rounded-full w-full h-full"></div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
