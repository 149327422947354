import React from 'react';

import clsx from 'clsx';

import { TableBody } from './TableBody';
import { TableHead } from './TableHead';
import { getWarning } from './utils';

interface TableProps {
  children?: JSX.Element | JSX.Element[];
  className?: string;
  'data-testid'?: string;
}

export const Table: React.FC<TableProps> = ({
  children,
  className,
  'data-testid': dataTestId,
}) => {
  return (
    <table className={clsx(className, 'w-full')} data-testid={dataTestId}>
      {React.Children.map(children, (child) => {
        if (![TableHead, TableBody].includes(child?.type as any)) {
          const warning = getWarning('Table', ['TableHead', 'TableBody']);
          console.error(warning);
        }
        return child;
      })}
    </table>
  );
};
