import React, { useRef } from 'react';

import {
  faCheck,
  faMagnifyingGlass,
  faPlus,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { Combobox } from '@headlessui/react';
import clsx from 'clsx';
import { Button, FontIcon } from 'components';
import { useAtom } from 'jotai';
import { debounce } from 'lodash';
import { useTranslation } from 'react-i18next';

import {
  canShowMoreThirdPartyCustomersAtom,
  thirdPartyCustomerIdsAtom,
  thirdPartyCustomerSearchAtom,
  thirdPartyCustomersAtom,
} from 'modules/EvaluationV2/models/account';

import { CustomerItem } from '../../../CustomerSelector/CustomerItem';
import { useCustomers } from '../../../CustomerSelector/CustomerSelector';

interface ThirdPartyAccountSearchProps {
  parentRef: React.RefObject<HTMLElement>;
}

const DEBOUNCE_MS = 500;

export const useParentPosition = (parentRef: React.RefObject<HTMLElement>) => {
  const element = parentRef.current;
  const [parentOffsetTop, setParentOffsetTop] = React.useState<number>(
    parentRef.current?.offsetTop || 0
  );
  const [parentScrollTop, setParentScrollTop] = React.useState<number>(
    parentRef.current?.scrollTop || 0
  );
  const [screenHeight, setScreenHeight] = React.useState<number>(window.innerHeight);
  React.useEffect(() => {
    const handleScroll = () => {
      setParentOffsetTop(parentRef.current?.offsetTop || 0);
      setParentScrollTop(parentRef.current?.scrollTop || 0);
      setScreenHeight(window.innerHeight);
    };
    element?.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);
    return () => {
      element?.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [element, parentRef]);
  return { parentOffsetTop, screenHeight, parentScrollTop };
};

export const ThirdPartyAccountSearchComponent: React.FC<ThirdPartyAccountSearchProps> = ({
  parentRef,
}) => {
  const customers = useCustomers(
    thirdPartyCustomersAtom,
    thirdPartyCustomerSearchAtom,
    canShowMoreThirdPartyCustomersAtom,
    false
  );
  const [customerSearch, setCustomerSearch] = useAtom(thirdPartyCustomerSearchAtom);
  const [customerIds, setCustomerIds] = useAtom(thirdPartyCustomerIdsAtom);
  const [canShowMoreCustomers] = useAtom(canShowMoreThirdPartyCustomersAtom);
  const inputRef = useRef<HTMLInputElement>(null);
  const dropdownRef = useRef<HTMLUListElement>(null);
  const dropdownContentRef = useRef<HTMLDivElement>(null);
  const { t } = useTranslation();
  const { parentOffsetTop, screenHeight } = useParentPosition(parentRef);

  const top = inputRef.current
    ? inputRef.current.getBoundingClientRect().y + 40 < parentOffsetTop
      ? parentOffsetTop
      : inputRef.current.getBoundingClientRect().y + 40
    : '';

  const dropdownOffsetTop = dropdownRef.current?.offsetTop || 0;
  const dropdownContentOffsetHeight = dropdownContentRef.current?.offsetHeight || 0;
  const bottom =
    dropdownOffsetTop + dropdownContentOffsetHeight >= screenHeight ? '0px' : '';

  const showNoResults = React.useMemo(
    () =>
      customers.length > 0 &&
      !customers.some((page) => page.items && page.items.length > 0),
    [customers]
  );

  const handleLoadMore = React.useCallback(() => {
    const searchText = customerSearch?.searchText || '';
    const fromIndex = (customerSearch?.fromIndex ?? 0) + 5;
    setCustomerSearch(searchText, fromIndex);
  }, [customerSearch?.searchText, customerSearch?.fromIndex, setCustomerSearch]);

  const debouncedSetCustomerSearch = React.useMemo(
    () => debounce(setCustomerSearch, DEBOUNCE_MS, { leading: false }),
    [setCustomerSearch]
  );

  return (
    <Combobox
      value={customerIds}
      onChange={(x) => {
        setCustomerIds(x);
      }}
      multiple
    >
      {({ open }) => (
        <>
          <div className="group w-full flex items-center border rounded box-border border-gray-50 bg-gray-50 hover:bg-gray-100 px-4 py-3 outline-primary-600 outline-2 focus-within:outline">
            <Combobox.Input
              ref={inputRef}
              className={
                'w-full leading-4 placeholder:text-gray-500 outline-none box-content text-base block bg-none border-0 bg-gray-50 group-hover:bg-gray-100'
              }
              onChange={(event) => {
                debouncedSetCustomerSearch(event.target.value, 0);
              }}
              placeholder={'Search customer by name or ID'}
            />
            {open ? (
              <FontIcon aria-label="clear" className="cursor-pointer" icon={faXmark} />
            ) : (
              <FontIcon aria-label="search" icon={faMagnifyingGlass} />
            )}
          </div>
          <Combobox.Options
            className={clsx(
              'w-[436px] fixed rounded-lg bg-white shadow-xl overflow-auto z-30 border'
            )}
            style={{ top, bottom }}
            ref={dropdownRef}
          >
            <div className="flex flex-col gap-3 p-3" ref={dropdownContentRef}>
              {customers.map((customer) =>
                customer.items?.map((cust) => (
                  <Combobox.Option key={cust?.id} value={cust?.customerId}>
                    {({ selected }) =>
                      cust ? (
                        <Combobox.Button as="div">
                          <CustomerItem
                            key={cust.id}
                            customer={cust}
                            onSelect={() => {}}
                            selected={selected}
                            icon={
                              <Button state={'secondary'}>
                                <FontIcon
                                  aria-label={selected ? 'delete' : 'add'}
                                  size="lg"
                                  icon={selected ? faCheck : faPlus}
                                />
                              </Button>
                            }
                            size="sm"
                          />
                        </Combobox.Button>
                      ) : (
                        <></>
                      )
                    }
                  </Combobox.Option>
                ))
              )}
              {canShowMoreCustomers && (
                <div className="text-center">
                  <Button state="secondary" onClick={handleLoadMore}>
                    {t('common.showMore')}
                  </Button>
                </div>
              )}
              {showNoResults && (
                <div data-testid="no-data" className="notification self-center">
                  {t('common.searchBox.noRecords')}
                </div>
              )}
            </div>
          </Combobox.Options>
        </>
      )}
    </Combobox>
  );
};
