import React from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';

import { PageLayout } from 'components';

import {
  CollateralAccounts,
  CustomerDetails,
  CustomerProfile,
  Household,
  InternalContacts,
  LoanSummary,
  Loans,
  SingleLoan,
} from 'modules/Customers/CustomerProfile';
import { Customers } from 'modules/Customers/Customers';
import { PageNotFound } from 'modules/Error/PageNotFound';
import {
  Evaluation,
  ExistingEvaluation,
  NewEvaluation,
  ReEvaluation,
} from 'modules/Evaluation';
import * as Dev from 'modules/EvaluationV2';
import { EvaluationLayout } from 'modules/EvaluationV2/EvaluationLayout';
import { Home } from 'modules/Home/Home';
import { LoanProposal } from 'modules/LoanProposal/LoanProposal';
import { LoginForm } from 'modules/LoginForm/LoginForm';
import { ApplicationsTable } from 'modules/Originations/ApplicationsTable';
import { EvaluationsTable } from 'modules/Originations/EvaluationsTable';
import { Originations } from 'modules/Originations/Originations';
import { Reports } from 'modules/Reports/Reports';
import { ThemeReview } from 'modules/ThemeReview/ThemeReview';
import { useCustomisation, usePageTitle, usePaths } from 'modules/root/Settings/';
import { Authenticated } from 'modules/root/auth/Authenticated/Authenticated';
import { Authorized } from 'modules/root/auth/Authorized/Authorized';

import { Resolver } from './Resolver';

const RoutesComponent: React.FC = React.memo(() => {
  const paths = usePaths();

  // This is default one to set it on each page
  usePageTitle();
  const { sideMenu, featureFlags } = useCustomisation();
  return (
    // Added it because we want to see Route in one line
    /* prettier-ignore */
    <Routes>
      <Route path="*" element={<PageLayout><PageNotFound /></PageLayout>} />
      <Route path={paths.auth.signIn} element={<LoginForm />} />
      <Route path={paths.themeReview} element={<ThemeReview />} />
      <Route element={<Authenticated><PageLayout><Outlet /></PageLayout></Authenticated>} >
        <Route path={paths.initial} element={<Resolver/>} />
        {sideMenu.home.show && <Route path={paths.home} element={<Authorized><Home /></Authorized>} />}
        {sideMenu.customers.show && <Route path={paths.customer.list} element={<Authorized permissionList={['canViewCustomers']}><Customers /></Authorized>} />}
        <Route path={paths.customer.main} element={<Authorized permissionList={['canViewCustomers']}><CustomerProfile /></Authorized>} >
          <Route path="" element={<Authorized permissionList={['canViewLoans']}><Loans /></Authorized>}> 
            <Route path="" element={<LoanSummary />} />
            <Route path={paths.customer.loans.details} element={<SingleLoan />} />
          </Route>
          <Route path={paths.customer.accounts} element={<Authorized permissionList={['canViewCollateralAccounts']}><CollateralAccounts /></Authorized>} />
          <Route path={paths.customer.details} element={<CustomerDetails />} />
          <Route path={paths.customer.contacts} element={<Authorized permissionList={['canViewInternalContacts']}><InternalContacts /></Authorized>} />
          <Route path={paths.customer.household} element={<Household />} />
        </Route>
        {sideMenu.originations.show && <Route path={paths.origination.list} element={<Originations />}>
          <Route path={paths.origination.evaluations} element={<Authorized permissionList={['canViewEvaluations']}><EvaluationsTable /></Authorized>} />
          <Route path={paths.origination.applications} element={<Authorized permissionList={['canViewApplications']}><ApplicationsTable /></Authorized>} />
        </Route>}
        {sideMenu.reports.show && <Route path={paths.reports} element={<Authorized><Reports /></Authorized>} />}
        {!featureFlags?.evaluationV2 && <Route element={<Authorized><Evaluation/></Authorized>}>
          <Route path={paths.evaluations.create} element={<NewEvaluation />} />
          <Route path={paths.evaluations.view} element={<ExistingEvaluation />} />
          <Route path={paths.evaluations.reevaluate} element={<ReEvaluation />} />
        </Route>}
        {featureFlags?.evaluationV2 && <Route element={<Authorized><Dev.EvaluationRoot/></Authorized>} >
          <Route path={paths.evaluationsV2.proceedNew} element={<EvaluationLayout />} />
          <Route path={paths.evaluationsV2.view} element={<EvaluationLayout />} />
        </Route>}
        <Route path={paths.evaluations.startapplication} element={<Authorized><LoanProposal /></Authorized>} />
        </Route>
    </Routes>
  );
});

RoutesComponent.displayName = 'PureRoutesComponent';

export { RoutesComponent };
