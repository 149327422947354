import React, { FC } from 'react';
import { useParams } from 'react-router';

import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontIcon, HelpIcon } from 'components';
import {
  AccountListQuery,
  AccountSource,
  ActivityAuditType,
  useAccountListQuery,
} from 'generated/graphql';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';

import { AccountItem } from 'modules/EvaluationV2/EvaluationStep/steps/AccountSelector/AccountItem';
import { CreditPolicyDropdown } from 'modules/EvaluationV2/EvaluationStep/steps/CreditPolicyDropdown/CreditPolicyDropdown';
import {
  AccountItemState,
  evalInputUnusedAccountsAtom,
  evaluationInputAccountsAtom,
  systemAccountIdsAtom,
  systemAccountsAtom,
} from 'modules/EvaluationV2/models/account';
import {
  isCustomerEligibleAtom,
  selCustomerAtom,
} from 'modules/EvaluationV2/models/customer';
import {
  EvaluationAction,
  EvaluationState,
  evaluationActionAtom,
  evaluationStateAtom,
} from 'modules/EvaluationV2/models/evaluation';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation } from 'modules/root/Settings';

interface EvaluationInputProps {}

export const EvaluationInput: FC<EvaluationInputProps> = (props) => {
  const { labels, helpText } = useCustomisation();
  const { t } = useTranslation();
  const { customerId = '' } = useParams();
  const evalState = useAtomValue(evaluationStateAtom);
  const evalAction = useAtomValue(evaluationActionAtom);
  const [systemAccounts, setSystemAccounts] = useAtom(systemAccountsAtom);
  const evaluationInputAccounts = useAtomValue(evaluationInputAccountsAtom);
  const evalInputUnusedAccounts = useAtomValue(evalInputUnusedAccountsAtom);
  const setSystemAccountIds = useSetAtom(systemAccountIdsAtom);
  const setSelCustomer = useSetAtom(selCustomerAtom);
  const isCustomerEligible = useAtomValue(isCustomerEligibleAtom);

  const [isUnusedAccountsShow, setIsUnusedAccountsShow] = React.useState(false);

  const { isLoading, isError } = useQueryFetch(useAccountListQuery, {
    queryHookParams: { customerId },
    queryHookOptions: {
      enabled: !!customerId && !systemAccounts && evalAction === EvaluationAction.View,
      onSuccess: (data) => {
        const customer = (data as AccountListQuery).customer;
        setSelCustomer({
          id: customer.id,
          customerId: customer.customerId,
          displayName: customer.displayName,
          serviceStatus: customer.serviceStatus?.status,
        });

        if (!systemAccounts || systemAccounts?.length === 0) {
          const accountsToSet =
            customer.collateralAccounts?.reduce<NonNullable<typeof systemAccounts>>(
              (_accounts, acc) => {
                if (acc) {
                  _accounts.push({
                    externalAccountId: acc.collateralAccountId || '',
                    source: AccountSource.System,
                    accountType: acc.accountType,
                    custodian: acc.custodian,
                    evaluationAssets: [],
                    id: acc.id,
                    ownerId: acc.ownerId,
                    pledged: acc.pledged,
                  });
                }
                return _accounts;
              },
              []
            ) || [];

          setSystemAccounts(accountsToSet);
          setSystemAccountIds(accountsToSet.map((acc) => acc.externalAccountId));
        }
      },
      onError: (error) => {
        // error handling
      },
    },
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });

  return (
    <div className="w-full flex flex-col p-5 xl:p-10 h-[calc(100vh-72px)] overflow-y-auto border-r border-r-gray-200 sticky top-0 text-sm xl:text-md">
      <QuerySuspense
        error={isError}
        isLoading={
          isLoading ||
          (evalState === EvaluationState.Processing &&
            evalAction === EvaluationAction.View)
        }
        noData={!evaluationInputAccounts || !systemAccounts}
      >
        <div className="flex flex-col w-full">
          {!isCustomerEligible && (
            <div className="alert" data-testid="not-eligible-alert">
              {t('evaluationV2.notEligible')}
            </div>
          )}
          <div className="font-bold text-lg mb-4">{`${t('evaluationV2.used')} ${
            labels.accounts
          }`}</div>
          <div className="flex flex-col gap-4 w-full mb-8 pr-2 overflow-y-auto">
            {evaluationInputAccounts &&
              evaluationInputAccounts.map(
                (account) =>
                  account && (
                    <AccountItem
                      key={account.id}
                      account={account}
                      state={AccountItemState.used}
                    />
                  )
              )}
          </div>
          {evalInputUnusedAccounts && evalInputUnusedAccounts.length > 0 && (
            <>
              <div
                role="button"
                onClick={(evt) => {
                  setIsUnusedAccountsShow(!isUnusedAccountsShow);
                  evt.stopPropagation();
                }}
                className=" mb-4 flex cursor-pointer"
              >
                <div className="font-bold text-lg mr-1">{`${t('evaluationV2.unused')} ${
                  labels.accounts
                } `}</div>
                <HelpIcon text={helpText.unusedAccountInfo} />
                <div className="grow"></div>
                <div>
                  {!isUnusedAccountsShow && <FontIcon icon={faAngleDown} />}
                  {isUnusedAccountsShow && <FontIcon icon={faAngleUp} />}
                </div>
              </div>
              {isUnusedAccountsShow && (
                <div className="flex flex-col gap-4 w-full mb-8 pr-2 overflow-y-auto">
                  {evalInputUnusedAccounts.map(
                    (account) =>
                      account && (
                        <AccountItem
                          key={account.id}
                          account={account}
                          state={AccountItemState.unused}
                        />
                      )
                  )}
                </div>
              )}
            </>
          )}

          <div className="font-bold text-lg mb-4">{labels.creditPolicy}</div>
          <div className="xl:mb-8 mb-2">
            <CreditPolicyDropdown disabled={true} />
          </div>
        </div>
      </QuerySuspense>
    </div>
  );
};
