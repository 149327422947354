import React from 'react';

import { faCircleXmark } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { Ellipsis, FontIcon, HiddenDetails, Tooltip } from 'components';
import { ParsedAssetError } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { Checkbox, CheckboxOnChange } from 'components/Checkbox/Checkbox';
import { EvaluationAccount } from 'modules/EvaluationV2/types';
import { useCustomisation } from 'modules/root/Settings';

interface ManualUploadErrorMessagesProps {
  errors: ParsedAssetError[];
}

export const ManualUploadErrorMessages: React.FC<ManualUploadErrorMessagesProps> = ({
  errors,
}) => {
  const { t } = useTranslation();
  return (
    <div data-testid="manual-accounts-errors" className="alert">
      <ul className="list-disc pl-2">
        {errors?.includes(ParsedAssetError.MissingInfo) && (
          <li className="my-1 text-left">{t('evaluations.missingInformationError')}</li>
        )}

        {errors?.includes(ParsedAssetError.Duplication) && (
          <li className="my-1 text-left">{t('evaluations.duplicateRecordsError')}</li>
        )}

        {errors?.includes(ParsedAssetError.AccountExists) && (
          <li className="my-1 text-left">
            {t('evaluations.accountsAlreadyExistsError')}
          </li>
        )}
      </ul>
    </div>
  );
};

interface AddAccountItemProps {
  account: EvaluationAccount;
  errors: ParsedAssetError[];
  selected?: boolean;
  onAccountSelect: (checked: boolean, value: string) => void;
}

export const AddAccountItem: React.FC<AddAccountItemProps> = ({
  account,
  errors,
  selected = false,
  onAccountSelect,
}) => {
  const { labels } = useCustomisation();
  const { t } = useTranslation();

  const handleAccountSelect: CheckboxOnChange = React.useCallback(
    ({ checked }) => {
      if (!errors?.length) {
        onAccountSelect(checked, account.externalAccountId);
      }
    },
    [account, onAccountSelect, errors]
  );

  return (
    <div
      data-testid="evaluation-add-account-item"
      className={clsx(
        'rounded-xl px-5 py-4 cursor-pointer w-full',
        'focus-within:outline focus-within:outline-2 focus-within:shadow-md',
        {
          'border-2 border-primary-600': selected,
          'border-gray-100 border hover:border-primary-200': !selected,
        }
      )}
      role="checkbox"
      aria-checked={selected}
      onClick={() => {
        handleAccountSelect({ checked: !selected });
      }}
    >
      <div className="flex items-center justify-between">
        <div className="grow">
          <div className="flex flex-col items-start">
            <div className="font-bold max-w-[200px]" title={labels.collateralAccountId}>
              <Ellipsis>{account.externalAccountId}</Ellipsis>
            </div>
            <div className="flex">
              <div className="max-w-[100px]" title={labels.owner}>
                <Ellipsis>
                  {account?.ownerDisplayName || t('evaluationV2.unavailable')}
                </Ellipsis>
              </div>
              <div className="px-2">{`|`}</div>
              <div className="max-w-[100px] grow" title={labels.custodian}>
                <Ellipsis>{account?.custodian || t('evaluationV2.unavailable')}</Ellipsis>
              </div>
            </div>
          </div>
        </div>
        <div className="flex gap-3 items-center">
          <div className="gap-1 max-w-[68px]" title={labels.accountType}>
            <Ellipsis>{account?.accountType || t('evaluationV2.unavailable')}</Ellipsis>
          </div>
          {errors?.length === 0 && (
            <Checkbox
              className="w-5"
              data-testid={`check-${account?.id}`}
              name={`account-${account.externalAccountId}`}
              checked={selected}
              onChange={handleAccountSelect}
              disabled={!!errors?.length}
              ariaLabel={account?.externalAccountId}
            />
          )}
          {errors?.length > 0 && (
            <Tooltip
              text={<ManualUploadErrorMessages errors={errors} />}
              className="leading-none"
            >
              <span data-testid="invalid-account">
                <FontIcon className="cursor-pointer text-red-500" icon={faCircleXmark} />
              </span>
            </Tooltip>
          )}
        </div>
      </div>
      {account.evaluationAssets && account.evaluationAssets.length > 0 && (
        <HiddenDetails className="mt-4">
          <div className="flex flex-col gap-2">
            <div className="flex justify-between">
              <div>{labels.securityId}</div>
              <div>{labels.units}</div>
            </div>
            {account.evaluationAssets.map((asset) => (
              <div className="flex justify-between" key={asset?.assetId}>
                <div className="flex gap-2">
                  <span>{'•'}</span>
                  {asset?.assetId}
                </div>
                <div>{asset?.quantity}</div>
              </div>
            ))}
          </div>
        </HiddenDetails>
      )}
    </div>
  );
};
