import React from 'react';
import { generatePath, useNavigate } from 'react-router-dom';

import {
  faCalculator,
  faFile,
  faFileDownload,
  faPenToSquare,
} from '@fortawesome/free-solid-svg-icons';
import { ActionMenu, FontIcon } from 'components';
import {
  ActivityAuditType,
  EvaluationSearchResultItem,
  EvaluationStatus,
  useAuditReportMutation,
} from 'generated/graphql';
import { graphqlClient, useAuthClient } from 'queries/apiFetch/graphqlClient';
import { ResourceAccessed } from 'services/auditReport';
import { DATE_FORMAT_UNDERSCORE, formatDate } from 'utils';

import { useCustomisation, usePaths } from 'modules/root/Settings';
import { useAccess } from 'modules/root/auth/Authenticated/Permission/permission.context';

interface EvaluationActionProps {
  evaluation: EvaluationSearchResultItem;
  downloadPdf: (urlPath: string, filename: string) => Promise<void>;
}

export const EvaluationAction: React.FC<EvaluationActionProps> = ({
  evaluation,
  downloadPdf,
}) => {
  const paths = usePaths();
  const navigate = useNavigate();
  const { permissions } = useAccess();
  const { labels, evaluationsActionModal, featureFlags } = useCustomisation();
  const authClient = useAuthClient(graphqlClient);
  const { mutateAsync } = useAuditReportMutation(authClient);

  const evaluationActions = React.useMemo(() => {
    const handleDownloadPdf = (evaluation: EvaluationSearchResultItem) => {
      const fileName = `Evaluation-${evaluation.id}-${formatDate(
        new Date().toUTCString(),
        DATE_FORMAT_UNDERSCORE
      )}`;
      const urlPath = `/schema/valuations/${evaluation.id}/pdf`;
      const commonAuditData = {
        activityType: ActivityAuditType.Read,
        resourceAccessed: ResourceAccessed.DownloadEvaluationDocs,
        customerExternalId: evaluation.customerExternalId,
      };
      downloadPdf(urlPath, fileName)
        .then(() => {
          mutateAsync({
            ...commonAuditData,
            queryDetails: JSON.stringify({ externalId: evaluation.externalId }),
          });
        })
        .catch(() => {
          mutateAsync({
            ...commonAuditData,
            queryDetails: JSON.stringify({
              externalId: evaluation.externalId,
              error: true,
            }),
          });
        });
    };

    const evaluationNavigate = (row: EvaluationSearchResultItem, path: string) => {
      if (row.customerId && row.id) {
        navigate(
          generatePath(path, {
            customerId: row.customerId,
            evaluationId: row.id,
          })
        );
      }
    };

    const actions = [];
    if (evaluationsActionModal.showFields.view && permissions.canViewEvaluations) {
      actions.push({
        action: (row: EvaluationSearchResultItem) => {
          mutateAsync({
            activityType: ActivityAuditType.Read,
            resourceAccessed: ResourceAccessed.ViewEvaluation,
            customerExternalId: row.customerExternalId,
            queryDetails: JSON.stringify({
              externalId: row.externalId,
            }),
          });
          evaluationNavigate(
            row,
            featureFlags?.evaluationV2 ? paths.evaluationsV2.view : paths.evaluations.view
          );
        },
        text: labels.view,
        icon: <FontIcon icon={faFile} />,
      });
    }
    if (
      evaluationsActionModal.showFields.reEvaluate &&
      permissions.canCreateModifyRunEvaluations &&
      !featureFlags?.evaluationV2
    ) {
      actions.push({
        action: (row: EvaluationSearchResultItem) => {
          mutateAsync({
            activityType: ActivityAuditType.Read,
            resourceAccessed: ResourceAccessed.ReEvaluateEvaluation,
            customerExternalId: row.customerExternalId,
            queryDetails: JSON.stringify({
              externalId: row.externalId,
            }),
          });
          evaluationNavigate(row, paths.evaluations.reevaluate);
        },
        text: labels.reEvaluate,
        icon: <FontIcon icon={faCalculator} />,
      });
    }
    if (
      evaluationsActionModal.showFields.startApplication &&
      permissions.canCreateLoanApplication
    ) {
      actions.push({
        action: (row: EvaluationSearchResultItem) => {
          mutateAsync({
            activityType: ActivityAuditType.Read,
            resourceAccessed: ResourceAccessed.LoanProposal,
            customerExternalId: row.customerExternalId,
            queryDetails: JSON.stringify({
              externalId: row.externalId,
            }),
          });
          evaluationNavigate(row, paths.evaluations.startapplication);
        },
        disabled: (row: EvaluationSearchResultItem) =>
          row.status !== EvaluationStatus.Active,
        text: labels.startApplication,
        icon: <FontIcon icon={faPenToSquare} />,
      });
    }
    if (
      evaluationsActionModal.showFields.downloadPdf &&
      permissions.canAccessEvaluationPdf
    ) {
      actions.push({
        action: (row: EvaluationSearchResultItem) => handleDownloadPdf(row),
        text: labels.downloadPdf,
        icon: <FontIcon icon={faFileDownload} />,
        disabled: (row: EvaluationSearchResultItem) =>
          row.status !== EvaluationStatus.Active,
      });
    }
    return actions;
  }, [
    downloadPdf,
    permissions,
    labels,
    evaluationsActionModal,
    navigate,
    paths,
    mutateAsync,
    featureFlags?.evaluationV2,
  ]);

  return (
    <ActionMenu
      position="center"
      items={evaluationActions}
      data={evaluation}
      data-testid="evaluation-action-menu"
    />
  );
};
