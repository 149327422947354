export const formatDecimalWithCommas = (value: string): string => {
  let num = parseFloat(removeCommas(value));
  if (Number.isNaN(num)) {
    return '0.00';
  }
  const roundedResult = roundDecimalPlaces(num, 2);
  return addCommaThousands(roundedResult);
};

export const formatDecimal = (value: string): string => {
  let num = parseFloat(value);
  if (Number.isNaN(num)) {
    return '0.00';
  }
  return roundDecimalPlaces(num, 2);
};

export const filterDecimal = (value: string): string => {
  return value.replace(/[^0-9.]/g, '');
};

export const addCommaThousands = (value: string) => {
  return value.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const removeCommas = (value: string) => {
  return value.replace(/,/g, '');
};

export const roundDecimalPlaces = (value: number, round: number) => {
  const num = Math.round((value + Number.EPSILON) * 100) / 100;
  return num.toFixed(2);
};

export const getNumberValue = (value: string): number => {
  return !!value ? parseFloat(removeCommas(value)) : 0;
};

export const getNumberValueWith2Decimal = (value: string): number => {
  return !!value ? parseFloat(roundDecimalPlaces(getNumberValue(value), 2)) : 0;
};
