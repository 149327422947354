import React from 'react';

import clsx from 'clsx';

export interface TableCellProps {
  children?: string | JSX.Element | JSX.Element[];
  className?: string | React.ReactElement;
  'data-testid'?: string;
}

export const TableCell: React.FC<TableCellProps> = ({
  children,
  className,
  'data-testid': dataTestId,
}) => {
  return (
    <td
      data-testid={dataTestId}
      className={clsx(
        className,
        'group-first:text-gray-500',
        'group-first:font-bold',
        'group-last:text-gray-700',
        'text-base',
        'group-first:p-4',
        'group-last:px-4',
        'group-last:py-5'
      )}
    >
      {children}
    </td>
  );
};
