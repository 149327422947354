import React from 'react';
import { generatePath } from 'react-router';

import { ButtonLink } from 'components';
import {
  ActivityAuditType,
  CustomerLoansQuery,
  useCustomerLoansQuery,
} from 'generated/graphql';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';
import { ResourceAccessed, useSendAuditReport } from 'services/auditReport';
import { ArrayElement } from 'utils';

import { DashboardList } from 'modules/Home/components/DashboardList';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';
import { useCustomisation, usePaths } from 'modules/root/Settings';
import { getShortener } from 'utils/helpers';

import { ActivityIcon, ActivitySection } from './ActivitySection';

interface BookedLoanSectionProps {
  customerId: string;
  customerExternalId: string;
}

type CustomerLoanItems = CustomerLoansQuery['customer']['loans'];

export const BookedLoanSection: React.FC<BookedLoanSectionProps> = ({
  customerId,
  customerExternalId,
}) => {
  const { labels } = useCustomisation();
  const { t } = useTranslation();
  const paths = usePaths();

  const { data, isLoading, error } = useQueryFetch(useCustomerLoansQuery, {
    queryHookParams: { customerID: customerId },
  });

  const clientNumberOfLoans = data?.customer.loans?.length || 0;
  const sendAudit = useSendAuditReport();
  const getHandlerLoanView = (loan: ArrayElement<CustomerLoanItems>) => () => {
    sendAudit({
      activityType: ActivityAuditType.Read,
      customerExternalId,
      resourceAccessed: ResourceAccessed.CustomerActivityViewLoan,
      queryDetails: JSON.stringify({ loanId: loan?.id }),
    });
  };

  const handleViewLoanSummary = () => {
    sendAudit({
      activityType: ActivityAuditType.Read,
      customerExternalId,
      resourceAccessed: ResourceAccessed.CustomerActivityViewLoanSummary,
    });
  };

  const shortNum = React.useMemo(() => {
    if (data?.customer?.loans?.length === 1) {
      return getShortener(36);
    } else if (data?.customer?.loans?.length === 2) {
      return getShortener(12);
    }
    return getShortener(8);
  }, [data?.customer?.loans]);

  const path = generatePath(paths.customer.main, {
    customerId,
  });
  return (
    <ActivitySection
      label={t('dashboard.activities.bookedLoan')}
      items={data?.customer?.loans || []}
      btn={
        clientNumberOfLoans >= 2
          ? {
              path,
              title: t('dashboard.activities.modal.viewLoanSummayDetails'),
              onClick: handleViewLoanSummary,
            }
          : undefined
      }
    >
      {(items: CustomerLoanItems) => (
        <QuerySuspense
          error={error}
          isLoading={isLoading}
          noData={data?.customer?.loans?.length === 0}
          noDataMessage={t('dashboard.activities.modal.noLoans')}
        >
          <DashboardList>
            {items?.map((loan, idx) => (
              <DashboardList.Item key={loan?.id}>
                <div
                  className="flex justify-between"
                  data-testid={`booked-loan-section-item`}
                >
                  <div className="flex items-center">
                    <ActivityIcon />
                    <span className="ml-[14px] mr-2">{labels.loan}</span>
                    <span>
                      {`#${shortNum(loan?.obligor)}-${shortNum(loan?.obligation)}`}
                    </span>
                  </div>
                  <ButtonLink
                    data-testid={`booked-loan-view-btn-${idx}`}
                    onClick={getHandlerLoanView(loan)}
                    state="secondary"
                    size="sm"
                    url={generatePath(paths.customer.loans.details, {
                      customerId,
                      loanId: loan?.id,
                    })}
                  >
                    {t('dashboard.activities.view')}
                  </ButtonLink>
                </div>
              </DashboardList.Item>
            ))}
          </DashboardList>
        </QuerySuspense>
      )}
    </ActivitySection>
  );
};
