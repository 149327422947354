import React from 'react';

import clsx from 'clsx';
import { Button } from 'components';
import {
  ActivityAuditType,
  CustomersSearchQuery,
  useCustomersSearchQuery,
} from 'generated/graphql';
import { useAtom, useSetAtom } from 'jotai';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';

import { SearchBox } from 'modules/Customers/SearchBox/SearchBox';
import { accountsAtom, selAccountIdsAtom } from 'modules/EvaluationV2/models/account';
import {
  canShowMoreCustomersAtom,
  customerSearchAtom,
  customersAtom,
  selCustomerAtom,
} from 'modules/EvaluationV2/models/customer';
import { Customer } from 'modules/EvaluationV2/types';
import { useCustomisation } from 'modules/root/Settings';

import { CustomerItem } from './CustomerItem';

interface ICustomerSelectorProps {
  className?: string;
}

export const useCustomers = (
  customerPagesAtom = customersAtom,
  searchAtom = customerSearchAtom,
  showMoreAtom = canShowMoreCustomersAtom,
  queryWhenSearchTextIsEmpty = true
) => {
  const [customers, setCustomers] = useAtom(customerPagesAtom);
  const [customerSearch] = useAtom(searchAtom);
  const setCanShowMoreCustomers = useSetAtom(showMoreAtom);

  useQueryFetch(useCustomersSearchQuery, {
    queryHookOptions: {
      enabled: queryWhenSearchTextIsEmpty
        ? !!customerSearch
        : !!customerSearch?.searchText,
      onSuccess(data) {
        const result = (data as CustomersSearchQuery).customersSearch;
        const fromIndex = customerSearch?.fromIndex ?? 0;
        const newCustomersPage = { items: result.items, fromIndex };
        if (fromIndex === 0) {
          // first page
          setCustomers([newCustomersPage]);
        } else {
          const pageIndex = customers.findIndex((page) => page.fromIndex === fromIndex);
          if (pageIndex !== -1) {
            // existing page
            const newCustomers = [...customers];
            newCustomers[pageIndex] = newCustomersPage;
            setCustomers(newCustomers);
          } else {
            // new page
            setCustomers(customers.concat(newCustomersPage));
          }
        }

        const numResults = result.numResults ?? 0;
        const totalHits = result.totalHits;
        setCanShowMoreCustomers(fromIndex + numResults < totalHits);
      },
    },
    queryHookParams: customerSearch ? customerSearch : undefined,
    extra: {
      auditReport: { activityType: ActivityAuditType.Read },
    },
  });
  return customers;
};

export const CustomerSelector: React.FC<ICustomerSelectorProps> = ({ className }) => {
  const [selCustomer, setSelCustomer] = useAtom(selCustomerAtom);
  const [customerSearch, setCustomerSearch] = useAtom(customerSearchAtom);
  const setSelAccounts = useSetAtom(selAccountIdsAtom);
  const setAccounts = useSetAtom(accountsAtom);
  const [canShowMoreCustomers] = useAtom(canShowMoreCustomersAtom);
  const { labels } = useCustomisation();
  const customers = useCustomers();
  const { t } = useTranslation();

  if (!customerSearch) {
    setCustomerSearch('', 0);
  }

  const showNoResults = React.useMemo(
    () =>
      customers.length > 0 &&
      !customers.some((page) => page.items && page.items.length > 0),
    [customers]
  );

  const handleSelect = React.useCallback(
    (customer: Customer) => {
      setSelCustomer(customer);
      setAccounts(null);
      setSelAccounts(new Set());
    },
    [setSelCustomer, setAccounts, setSelAccounts]
  );

  const handleSearch = React.useCallback(
    (value: string) => {
      setCustomerSearch(value, 0);
    },
    [setCustomerSearch]
  );

  const handleLoadMore = React.useCallback(() => {
    const searchText = customerSearch?.searchText || '';
    const fromIndex = (customerSearch?.fromIndex ?? 0) + 5;
    setCustomerSearch(searchText, fromIndex);
  }, [customerSearch?.searchText, customerSearch?.fromIndex, setCustomerSearch]);

  return (
    <div className={clsx('w-full', className)}>
      <div className="flex flex-col">
        <h2 className="text-lg font-bold mb-8">{labels.chooseCustomer}</h2>
        <SearchBox
          className="mb-8"
          initialValue={customerSearch?.searchText || ''}
          onChange={handleSearch}
          placeholder={`${t('common.searchBox.find')} ${labels.customers}`}
        />
        <div className="flex flex-col gap-3 mb-3">
          {customers?.map((page) => (
            <React.Fragment key={page.fromIndex}>
              {page?.items?.map((cust) =>
                cust ? (
                  <CustomerItem
                    key={cust.id}
                    customer={cust}
                    onSelect={handleSelect}
                    selected={cust.id === selCustomer?.id}
                  />
                ) : null
              )}
            </React.Fragment>
          ))}
        </div>
        {canShowMoreCustomers && (
          <div className="text-center">
            <Button state="secondary" onClick={handleLoadMore}>
              {t('common.showMore')}
            </Button>
          </div>
        )}
        {showNoResults && (
          <div data-testid="no-data" className="notification self-center">
            {t('common.searchBox.noRecords')}
          </div>
        )}
      </div>
    </div>
  );
};
