import React from 'react';

import { LoadedButton } from 'components';
import { ActivityAuditType, useAuditReportMutation } from 'generated/graphql';
import { useAtomValue, useSetAtom } from 'jotai';
import { graphqlClient, useAuthClient } from 'queries/apiFetch/graphqlClient';
import { useTranslation } from 'react-i18next';
import { useDownloadPdf } from 'services';
import { ResourceAccessed } from 'services/auditReport';

import { selCustomerAtom } from 'modules/EvaluationV2/models/customer';
import { saveDownloadStateAtom } from 'modules/EvaluationV2/models/evaluation';
import { NotificationAtom, NotificationType } from 'modules/common/models/notify';
import { DATE_FORMAT_UNDERSCORE, formatDate } from 'utils/date';

interface DownloadPDFProps {}

export const DownloadPDF: React.FC<DownloadPDFProps> = (props) => {
  const customerExternalId = useAtomValue(selCustomerAtom)?.customerId;
  const saveDownloadState = useAtomValue(saveDownloadStateAtom);
  const setNotification = useSetAtom(NotificationAtom);
  const evaluationId = saveDownloadState?.evaluationId;
  const { t } = useTranslation();

  const {
    download,
    isError: pdfDownloadError,
    isLoading: pdfIsLoading,
  } = useDownloadPdf();
  const authClient = useAuthClient(graphqlClient);
  const { mutateAsync } = useAuditReportMutation(authClient);
  const handleDownloadPdf = (id: string) => {
    const fileName = `Evaluation-${id}-${formatDate(
      new Date().toUTCString(),
      DATE_FORMAT_UNDERSCORE
    )}`;
    const urlPath = `/schema/valuations/${id}/pdf`;
    download(urlPath, fileName)
      .then(() =>
        mutateAsync({
          activityType: ActivityAuditType.Read,
          customerExternalId,
          resourceAccessed: ResourceAccessed.DownloadEvaluationPdf,
          queryDetails: JSON.stringify({ evaluationId: id }),
        })
      )
      .catch(() => {
        mutateAsync({
          activityType: ActivityAuditType.Read,
          customerExternalId,
          resourceAccessed: ResourceAccessed.DownloadEvaluationPdf,
          queryDetails: JSON.stringify({ evaluationId: id, error: true }),
        });
      });
  };

  React.useEffect(() => {
    if (pdfDownloadError) {
      setNotification({
        type: NotificationType.error,
        message: t('common.error.unspecific'),
      });
      setTimeout(() => setNotification(null), 3000);
    }
  }, [pdfDownloadError, setNotification, t]);

  return (
    <LoadedButton
      onClick={() => handleDownloadPdf(evaluationId || '')}
      state="secondary"
      isLoading={pdfIsLoading}
      disabled={!saveDownloadState?.enableDownloadPdf || !evaluationId || pdfIsLoading}
      data-testid={'download-pdf-btn'}
    >
      {t('evaluationV2.download')}
    </LoadedButton>
  );
};
