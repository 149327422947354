import React from 'react';
import {
  generatePath,
  matchPath,
  useLocation,
  useNavigate,
  useParams,
} from 'react-router';

import clsx from 'clsx';

import { useSearchPlaceholder } from 'modules/Originations/searchPlaceholder.context';
import { useCustomisation, usePaths } from 'modules/root/Settings';

export interface TabItem {
  key: string;
  label: string;
  path: string | string[];
  show: boolean;
}

interface TabsProps {
  tabs: TabItem[];
  border?: boolean;
  noBackground?: boolean;
}

interface TabProps {
  tab: TabItem;
  onClick: () => void;
  noBackground?: boolean;
}
const isActiveTab = (path: string, currentPath: string) =>
  Boolean(matchPath({ path: path, end: true }, currentPath));

const Tab: React.FC<TabProps> = ({ tab, onClick, noBackground }) => {
  const location = useLocation();
  const { labels } = useCustomisation();
  const paths = usePaths();
  const { setSearchPlaceholderText } = useSearchPlaceholder();
  let active = false;
  if (typeof tab.path === 'string') {
    active = isActiveTab(tab.path, location.pathname);
  } else {
    active = tab.path.some((path) => isActiveTab(path, location.pathname));
  }
  React.useEffect(() => {
    if (active && tab.path === paths.origination.evaluations) {
      setSearchPlaceholderText(labels.evaluations);
    } else if (active && tab.path === paths.origination.applications) {
      setSearchPlaceholderText(labels.applications);
    }
  }, [labels, paths, location.pathname, setSearchPlaceholderText, tab.path, active]);
  return (
    <li
      className={clsx(
        'text-xs',
        'px-3',
        'py-2',
        'uppercase',
        'cursor-pointer',
        'rounded-t-lg',
        'hover:bg-white/20',
        'transition-colors',
        'duration-300',
        {
          [`bg-white text-primary-600 font-semibold hover:bg-white`]:
            active && !noBackground,
          [`text-primary-600 font-semibold underline underline-offset-4`]:
            active && noBackground,
        }
      )}
      onClick={onClick}
    >
      {tab.label}
    </li>
  );
};

export const Tabs: React.FC<TabsProps> = ({ tabs, border, noBackground }) => {
  const navigate = useNavigate();
  const { customerId } = useParams();
  const handleTabClick = (path: string | string[]) => () => {
    let to;
    if (typeof path === 'string') {
      to = generatePath(path, { customerId });
    } else {
      to = generatePath(path[0], { customerId });
    }

    navigate(to);
  };
  return (
    <ul data-testid="tabs" className={clsx('flex', border && 'border-b')}>
      {tabs.map((tab: TabItem) => {
        if (!tab.show) {
          return null;
        }
        return (
          <Tab
            key={tab.key}
            tab={tab}
            onClick={handleTabClick(tab.path)}
            noBackground={noBackground}
          />
        );
      })}
    </ul>
  );
};
