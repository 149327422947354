import React from 'react';

import {
  IconDefinition,
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark,
} from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';

import { FontIcon } from 'components/FontIcon/FontIcon';

export type NotificationType = 'error' | 'warning' | 'success' | 'info';

export interface NotificationProps {
  type: NotificationType;
  children: JSX.Element | string | null;
  className?: string;
  showIcon?: boolean;
  icon?: IconDefinition;
  'data-testid'?: string;
}

const iconMap: { [key in NotificationType]: IconDefinition } = {
  error: faCircleXmark,
  warning: faCircleExclamation,
  success: faCircleCheck,
  info: faCircleInfo,
};

const classMap: { [key in NotificationType]: string } = {
  error: 'alert',
  warning: 'warning',
  success: 'success',
  info: 'notification',
};

export const Notification: React.FC<NotificationProps> = ({
  type,
  children,
  className,
  showIcon = true,
  icon,
  'data-testid': dataTestId,
}) => (
  <div
    className={clsx(classMap[type], className, 'flex items-center gap-4')}
    data-testid={dataTestId}
  >
    {showIcon && (
      <FontIcon icon={icon ?? iconMap[type]} size="lg" testid={`${dataTestId}-icon`} />
    )}
    {children}
  </div>
);
