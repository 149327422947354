import React from 'react';

export interface SearchPlaceholder {
  setSearchPlaceholderText: React.Dispatch<React.SetStateAction<string>>;
}

export const SearchPlaceholderContext = React.createContext<SearchPlaceholder>(
  {} as SearchPlaceholder
);

export const useSearchPlaceholder = (): SearchPlaceholder =>
  React.useContext(SearchPlaceholderContext);
