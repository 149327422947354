import React from 'react';

import clsx from 'clsx';

import { TableCell } from './TableCell';
import { getWarning } from './utils';

export interface TableRowProps {
  children?: JSX.Element | JSX.Element[];
  onRowClick?: (event: React.MouseEvent<HTMLTableRowElement>) => void;
  className?: string;
  'data-testid'?: string;
}

export const TableRow: React.FC<TableRowProps> = ({
  children,
  className,
  onRowClick,
  'data-testid': dataTestId,
}) => {
  return (
    <tr
      data-testid={dataTestId}
      className={clsx(className, 'border-0', 'border-opacity-100', {
        'cursor-pointer': !!onRowClick,
        'hover:bg-primary-200/10': !!onRowClick,
      })}
      onClick={onRowClick}
    >
      {React.Children.map(children, (child) => {
        if (child?.type !== TableCell) {
          const warning = getWarning('TableRow', 'TableCell');
          console.error(warning);
        }
        return child;
      })}
    </tr>
  );
};
