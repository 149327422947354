import React, { useMemo } from 'react';
import { NavLink } from 'react-router-dom';

import { faCircleUser } from '@fortawesome/free-solid-svg-icons';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { Popover } from '@headlessui/react';
import clsx from 'clsx';
import { Ellipsis, FontIcon, NavIcon } from 'components';
import { useTranslation } from 'react-i18next';

import { ReactComponent as CustomerIcon } from 'assets/icons/customers.svg';
import { ReactComponent as HomeIcon } from 'assets/icons/home.svg';
import { ReactComponent as OriginationIcon } from 'assets/icons/origination.svg';
import { ReactComponent as ReportsIcon } from 'assets/icons/reports.svg';

import { useCustomisation } from 'modules/root/Settings';
import { useBranding } from 'modules/root/Settings/contexts/branding';
import { useAuth } from 'modules/root/auth/auth.context';

interface SideMenuProps {
  title: string;
  setCollapsed: (collapsed: boolean) => void;
  collapsed: boolean;
}

interface Menu {
  label: string;
  icon: React.ReactElement;
  to: string;
  show: boolean;
}

export const SideMenu: React.FC<SideMenuProps> = ({ title, setCollapsed, collapsed }) => {
  const { t } = useTranslation();
  const { sideMenu, labels } = useCustomisation();
  const { sideMenuLogo } = useBranding();
  const menu: Menu[] = useMemo(() => {
    return [
      {
        label: labels.home,
        icon: <HomeIcon title={labels.home} />,
        to: `/${sideMenu.home.navKey}`,
        show: sideMenu.home.show,
      },
      {
        label: labels.customers,
        icon: <CustomerIcon title={labels.customers} />,
        to: `/${sideMenu.customers.navKey}`,
        show: sideMenu.customers.show,
      },
      {
        label: labels.originations,
        icon: <OriginationIcon title={labels.originations} />,
        to: `/${sideMenu.originations.navKey}`,
        show: sideMenu.originations.show,
      },
      {
        label: labels.reports,
        icon: <ReportsIcon title={labels.reports} />,
        to: `/${sideMenu.reports.navKey}`,
        show: sideMenu.reports.show,
      },
    ];
  }, [sideMenu, labels]);
  const { profile, logout } = useAuth();
  const handleLogout = () => {
    logout();
  };

  const handleClick = (): void => {
    setCollapsed(!collapsed);
  };

  return (
    <>
      <nav
        className={clsx([
          'flex flex-col bg-main-secondary shadow-sidebar fixed top-0 h-[100dvh]',
          collapsed ? 'w-[80px] items-center' : 'w-[256px]',
          'transition-all duration-200',
        ])}
      >
        <div className={clsx(['flex pt-6 space-x-3', collapsed ? '' : 'pl-9'])}>
          <NavIcon source={sideMenuLogo} alt="broadridge" />
          {!collapsed && (
            <div className="uppercase text-primary-600 text-center font-bold animate-slide-in overflow-hidden">
              {title}
            </div>
          )}
          <FontIcon
            className={clsx([
              'h-4 w-4 p-2',
              'rounded-full absolute -right-4',
              'border border-gray-200 bg-main-secondary shadow-sm',
              'duration-100',
              { '-scale-x-100 -mr-10': collapsed },
            ])}
            testid="toggle-sidemenu"
            icon={faAngleDoubleLeft}
            onClick={handleClick}
          />
        </div>

        <section className="flex flex-col pt-12 px-3 w-full grow overflow-hidden">
          {menu.map((elem: Menu) => {
            return (
              elem.show && (
                <NavLink
                  key={elem.label}
                  to={elem.to}
                  className={({ isActive }) =>
                    clsx(
                      'flex',
                      'items-center',
                      'w-full',
                      collapsed ? 'pl-[18px]' : 'pl-7',
                      'my-0.5',
                      'font-semibold',
                      'h-14',
                      { 'bg-gray-100 rounded-lg text-primary-600': isActive },
                      { 'text-gray-500 hover:text-primary-600 rounded-lg': !isActive }
                    )
                  }
                >
                  <div className="mr-2.5 w-5"> {elem.icon}</div>
                  {collapsed ? null : (
                    <span className="animate-slide-in">{elem.label}</span>
                  )}
                </NavLink>
              )
            );
          })}
        </section>
        <section className="flex items-center mb-6 px-6 w-full">
          {!collapsed && (
            <div
              className="grow max-w-[164px] text-primary-600 mr-3 font-semibold align-middle break-words overflow-hidden"
              data-testid="user-name"
            >
              <Ellipsis className={'animate-slide-in'}>
                {profile ? profile?.name || profile.username : ''}
              </Ellipsis>
            </div>
          )}
          <Popover className="relative h-8">
            <Popover.Button
              className={clsx(['rounded-full  w-8 cursor-pointer'])}
              data-testid="user-menu-btn"
            >
              {profile?.picture ? (
                <NavIcon
                  source={profile?.picture}
                  alt={profile?.name}
                  className="rounded-full"
                />
              ) : (
                <FontIcon className="h-8 w-8 mr-2" icon={faCircleUser} />
              )}
            </Popover.Button>
            <Popover.Panel
              className={clsx([
                'absolute z-10 bottom-11',
                collapsed ? 'left-0' : 'right-0',
                'bg-white rounded shadow',
              ])}
            >
              <div
                className="cursor-pointer px-4 py-2 text-primary-600 font-semibold"
                onClick={handleLogout}
                data-testid="logout-btn"
              >
                {t('auth.logout')}
              </div>
            </Popover.Panel>
          </Popover>
        </section>
      </nav>
    </>
  );
};
