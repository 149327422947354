import React from 'react';

import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { Button, FontIcon } from 'components';
import { CustomerLoanApplicationActivitiesSearchQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';
import { ArrayElement, formatDate } from 'utils';

import { ActivityBadge } from 'modules/common/ActivityBadge/ActivityBadge';
import { CustomerIcon } from 'modules/common/CustomerIcon/CustomerIcon';
import { notEmpty } from 'utils/helpers';

interface LoanApplicationActivityProps {
  customerLoanApplicationActivities: ArrayElement<
    CustomerLoanApplicationActivitiesSearchQuery['customerLoanApplicationActivitiesSearch']['items']
  >;
  showLoanApplicationActivityModal: (state: ModalState) => () => void;
}

type ModalState = {
  customerName: string;
  customerId: string;
  customerExternalId: string;
  applicationId: string;
};

const DEFAULT_NUMBER_OF_APPLICATIONS = 3;

export const LoanApplicationActivity: React.FC<LoanApplicationActivityProps> = ({
  customerLoanApplicationActivities: activity,
  showLoanApplicationActivityModal,
}) => {
  const { t } = useTranslation();
  const [showMore, setShowMore] = React.useState(false);
  const activities = activity?.activities?.filter(notEmpty);

  return (
    activity && (
      <div
        className="flex flex-row w-full gap-x-2 overflow-hidden"
        data-testid="customer-loan-applications-record"
      >
        <div className="w-10">
          <CustomerIcon customerName={activity.displayName || ''} />
        </div>
        <div className="flex flex-col grow  min-w-0 gap-y-2">
          <div
            className="font-semibold leading-4 pb-2 mt-1"
            data-testid="loan-applicaton-customer-name"
          >
            {activity.displayName}
          </div>
          {activities
            ?.slice(0, showMore ? activities.length : DEFAULT_NUMBER_OF_APPLICATIONS)
            .map((loanApplicationActivity) => {
              return (
                <div
                  data-testid="loan-application-record"
                  key={loanApplicationActivity.referenceId}
                  className="border-b last:border-b-0 pb-3 last:pb-0 cursor-pointer flex flex-col gap-y-2"
                  onClick={showLoanApplicationActivityModal({
                    applicationId: loanApplicationActivity.referenceId,
                    customerName: activity.displayName || '',
                    customerId: activity.id,
                    customerExternalId: activity.customerExternalId,
                  })}
                >
                  <div className="flex flex-row gap-x-2" data-testid="loan-application">
                    <div className="flex flex-row gap-x-2 grow text-sm">
                      <span data-testid="loan-application-name">
                        {t('dashboard.activities.application')}
                      </span>
                      <span data-testid="loan-application-id" className="w-16">{`${
                        loanApplicationActivity.referenceId.split('-')[0]
                      }`}</span>
                      <div className="whitespace-nowrap text-2xs ml-2">
                        <ActivityBadge
                          status={loanApplicationActivity.description}
                          size="sm"
                          showEllipsis={true}
                        />
                      </div>
                    </div>

                    <div
                      className="text-xs whitespace-nowrap"
                      data-testid="loan-application-updated-date"
                    >
                      {formatDate(loanApplicationActivity.eventOccurredAt, 'MMM D, YYYY')}
                    </div>
                    <div className="flex items-center ">
                      <FontIcon
                        size="sm"
                        className="text-gray-300 mr-2"
                        icon={faAngleRight}
                      />
                    </div>
                  </div>
                </div>
              );
            })}
          {activities && activities.length > DEFAULT_NUMBER_OF_APPLICATIONS && (
            <div className="text-center">
              <Button
                onClick={() => setShowMore(!showMore)}
                state="link"
                size="sm"
                data-testid="show-more-applications"
              >
                {showMore
                  ? t('common.showLess')
                  : `${t('common.showMore')} (${
                      activities.length - DEFAULT_NUMBER_OF_APPLICATIONS
                    })`}
              </Button>
            </div>
          )}
        </div>
      </div>
    )
  );
};
