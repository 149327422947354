import React from 'react';

import { faAngleRight, faEllipsisH } from '@fortawesome/free-solid-svg-icons';
import { Menu, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { FontIcon } from 'components';

type Item = {
  action: (data?: any) => void;
  icon?: JSX.Element;
  text: string;
  disabled?: boolean | ((data: any) => boolean);
};

interface NavMenuProps {
  items: Array<Item>;
  data?: any;
  position?: 'left' | 'right' | 'center';
  'data-testid'?: string;
}

export const ActionMenu: React.FC<NavMenuProps> = ({
  items,
  data,
  'data-testid': dataTestId,
  position = 'right',
}) => {
  return (
    <Menu>
      <Menu.Button
        aria-label="View Actions"
        data-testid={dataTestId}
        className="hover:bg-gray-100 hover:text-primary-600 
      text-gray-500 py-1 px-2 rounded-md transition-colors duration-300"
      >
        <FontIcon icon={faEllipsisH} />
      </Menu.Button>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-300"
        enterFrom="transform opacity-0 scale-90"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-90"
      >
        <Menu.Items
          className={clsx('w-min absolute bg-white', {
            '-translate-x-[80%]': position === 'left',
            '-translate-x-[45%]': position === 'center',
          })}
          data-testid={`${dataTestId}-items`}
        >
          <div className="flex flex-col bg-secondary-50/50 w-min p-2 shadow-lg rounded-md">
            {items.map((item) => (
              <Menu.Item key={item.text}>
                {({ active }) => (
                  <button
                    onClick={() => item.action(data)}
                    className={clsx(
                      'whitespace-nowrap p-1 rounded-md text-left pr-6 disabled:opacity-50',
                      {
                        'bg-gray-100': active,
                        'text-primary-600': active,
                        'text-gray-500': !active,
                      }
                    )}
                    disabled={
                      typeof item.disabled === 'function'
                        ? item.disabled(data)
                        : item.disabled
                    }
                  >
                    <div className="w-6 h-6 inline-block text-center mr-1">
                      {item.icon ? item.icon : <FontIcon icon={faAngleRight} />}
                    </div>
                    {item.text}
                  </button>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};
