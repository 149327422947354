import React from 'react';
import { generatePath, useParams } from 'react-router';

import { BreadCrumb, BreadCrumbItemType, Button, TabItem, Tabs } from 'components';
import { CustomerHeaderQuery } from 'generated/graphql';
import { useTranslation } from 'react-i18next';

import { StatusBadge } from 'modules/common';
import { useCustomisation, usePaths } from 'modules/root/Settings';
import { DATE_FORMAT_MONTH_NAME_TIME, formatDate } from 'utils/date';
import { EMPTY_DASH } from 'utils/print';

interface CustomerHeaderProps {
  customer: CustomerHeaderQuery['customer'];
  tabs: TabItem[];
}

export const CustomerHeader: React.FC<CustomerHeaderProps> = ({ customer, tabs }) => {
  const { t } = useTranslation();
  const { customerId } = useParams();
  const paths = usePaths();
  const { customerHeader, labels, sideMenu, serviceStatus } = useCustomisation();
  const breadcrumbList: BreadCrumbItemType[] = [];
  if (sideMenu.home.show) {
    breadcrumbList.push({ label: labels.home, link: generatePath(paths.home) });
  }
  if (sideMenu.customers.show) {
    breadcrumbList.push({
      label: labels.customers,
      link: generatePath(paths.customer.list),
    });
  }
  breadcrumbList.push({
    label: labels.customerProfile,
    link: generatePath(paths.customer.main, { customerId: customerId }),
  });

  return (
    <div className="flex flex-col h-full text-white" data-testid="customerHeader">
      <BreadCrumb list={breadcrumbList} />
      <div className="grid grid-cols-2 mb-8">
        {customer && (
          <div className="grid grid-cols-5 mt-6">
            <h1
              className="font-bold text-2xl my-1 col-span-4 break-words text-left flex-wrap items-center"
              data-testid="customername"
            >
              {customer.displayName}
            </h1>
            <div className="mx-2 whitespace-nowrap justify-self-center self-center">
              {customer.serviceStatus?.status && (
                <StatusBadge
                  status={customer.serviceStatus.status}
                  label={serviceStatus[customer.serviceStatus.status].label}
                  data-testid="customerstatus"
                />
              )}
            </div>

            <div className="font-normal text-xs mt-2 col-span-4" data-testid="lastupdate">
              {t('common.lastUpdate')}
              <span className="mr-1">:</span>
              {customer.lastUpdateDate
                ? formatDate(customer.lastUpdateDate, DATE_FORMAT_MONTH_NAME_TIME)
                : EMPTY_DASH}
            </div>
          </div>
        )}
        <div className="justify-self-end mt-6 flex flex-nowrap h-12">
          {customerHeader.showButtons.releaseCollateral && (
            <Button
              state="secondary"
              className="mr-4"
              data-testid="release-collateral-button"
            >
              {t('customer.customerHeader.releaseCollateral')}
            </Button>
          )}
          {customerHeader.showButtons.requestDrawdown && (
            <Button state="secondary" data-testid="request-drawdown-button">
              {t('customer.customerHeader.requestDrawdown')}
            </Button>
          )}
        </div>
      </div>
      <Tabs tabs={tabs} />
    </div>
  );
};
