import React from 'react';

import { ActivityAuditType, useAuditReportMutation } from 'generated/graphql';
import { auditReportMutation } from 'queries/common/auditReport';

import { graphqlClient } from '../queries/apiFetch/graphqlClient';
import { useQueryMutation } from '../queries/apiFetch/useQueryMutation';

export enum ResourceAccessed {
  LandOnHomePage = 'landOnHomePage',
  NewEvaluationButton = 'newEvaluationButton',
  NewEvaluationSeachCustomer = 'newEvaluationSeachCustomer',
  NewEvaluationSelectCustomer = 'newEvaluationSelectCustomer',

  // Downloads
  DownloadLoanPdf = 'downloadLoanPdf',
  DownloadEvaluationPdf = 'downloadEvaluationPdf',
  DownloadApplicationDocs = 'downloadApplicationDocs',
  DownloadEvaluationDocs = 'downloadEvaluationDocs',

  // Originations
  ViewApplication = 'viewApplication',
  ViewEvaluation = 'viewEvaluation',
  ReEvaluateEvaluation = 'reEvaluateEvaluation',
  LoanProposal = 'loanProposal',

  // home alerts
  ViewAllCustomerAlerts = 'viewAllCustomerAlerts',
  ViewCustomerAlert = 'viewCustomerAlert',
  ViewCustomerAlertDetails = 'viewCustomerAlertDetails',

  // home activities
  ViewAllCustomerActivities = 'viewAllCustomerActivities',
  ViewCustomerActivity = 'viewCustomerActivity',
  CustomerActivityViewCustomerProfile = 'customerActivityViewCustomerProfile',
  CustomerActivityViewEvaluation = 'customerActivityViewEvaluation',
  CustomerActivityViewApplication = 'customerActivityViewApplication',
  CustomerActivityViewLoan = 'customerActivityViewLoan',
  CustomerActivityViewLoanSummary = 'customerActivityViewLoanSummary',
  CustomerActivityNewEvaluation = 'customerActivityNewEvaluation',

  // home loan applications
  ViewAllLoanApplications = 'viewAllLoanApplications',
  ViewCustomerLoanApplication = 'viewCustomerLoanApplication',
}

type AuditOptions = {
  activityType: ActivityAuditType;
  resourceAccessed?: ResourceAccessed;
  customerExternalId?: string;
  queryDetails?: string;
};

export const sendAuditReport = (idToken: string, audit: AuditOptions): Promise<void> => {
  graphqlClient.setHeaders({
    Authorization: `Bearer ${idToken}`,
  });
  return graphqlClient.request(auditReportMutation, audit);
};

export const useSendAuditReport = ({
  audit: {
    activityType = ActivityAuditType.Read,
    resourceAccessed,
    customerExternalId,
    queryDetails,
  } = {},
  sendOnMount = false,
}: {
  audit?: Partial<AuditOptions>;
  sendOnMount?: boolean;
} = {}) => {
  const { mutateAsync } = useQueryMutation(useAuditReportMutation);
  const send = React.useCallback(
    async (variables: Partial<AuditOptions> = {}) => {
      return mutateAsync({
        activityType,
        resourceAccessed,
        customerExternalId,
        queryDetails,
        ...variables,
      });
    },
    [activityType, customerExternalId, mutateAsync, queryDetails, resourceAccessed]
  );

  React.useEffect(() => {
    if (sendOnMount) {
      send();
    }
  }, [sendOnMount, send]);
  return send;
};
