import React, { FC } from 'react';

import { LoadingSpinner, notification } from 'components';
import {
  AccountSource,
  ActivityAuditType,
  Currency,
  EvaluationAsset,
  ParseAssetCsvQuery,
  UnpledgedAccountsManyCustomersQuery,
  UnpledgedAccountsQuery,
  useUnpledgedAccountsManyCustomersQuery,
  useUnpledgedAccountsQuery,
} from 'generated/graphql';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';

import {
  AccountSourceUI,
  ManualAccountErrorsAtom,
  accountSourceAtom,
  csvDataAtom,
  manualAccountErrorsAtom,
  manualAccountsAtom,
  selManualAccountIdsAtom,
  showExtraStepAtom,
  systemAccountIdsAtom,
  thirdPartyCustomerIdsAtom,
} from 'modules/EvaluationV2/models/account';
import { selCustomerAtom } from 'modules/EvaluationV2/models/customer';
import {
  EvaluationAccount,
  ManualAccount,
  ManualAccountRecord,
} from 'modules/EvaluationV2/types';
import { QuerySuspense } from 'modules/common/QuerySuspense/QuerySuspense';

import { AddAccountItem } from './AddAccountItem';
import { useCsvFileUpload } from './csvUpload';

interface AddAccountSelectorProps {}

const toRunEvaluationAccount = (account: ManualAccount): EvaluationAccount => {
  const evaluationAssets = account?.assetHoldings?.reduce<EvaluationAsset[]>(
    (assets, asset) => {
      if (asset) {
        assets?.push({
          assetId: asset?.securityId,
          currency: Currency.Usd,
          quantity: asset?.quantity,
          type: asset?.assetType,
        });
      }
      return assets;
    },
    []
  );

  return {
    id: account?.id,
    externalAccountId: account.collateralAccountId,
    accountType: account?.accountType,
    ownerId: account?.ownerId,
    ownerDisplayName: account.ownerDisplayName,
    custodian: account?.custodian,
    pledged: false,
    source: AccountSource.Manual,
    evaluationAssets,
  };
};

const useInitAllAccountsQueries = () => {
  const selCustomer = useAtomValue(selCustomerAtom);
  const systemAccountIds = useAtomValue(systemAccountIdsAtom);
  const [accountSource, setAccountSource] = useAtom(accountSourceAtom);
  const setManualAccounts = useSetAtom(manualAccountsAtom);
  const setManualAccountErrors = useSetAtom(manualAccountErrorsAtom);
  const csvData = useAtomValue(csvDataAtom);
  const thirdPartyCustomerIds = useAtomValue(thirdPartyCustomerIdsAtom);
  const setSelManualAccountIds = useSetAtom(selManualAccountIdsAtom);
  const [showExtraStep, setShowExtraStep] = useAtom(showExtraStepAtom);
  const { t } = useTranslation();
  const [apiWarning, setApiWarning] = React.useState('');

  const handleError = (error?: string) => {
    notification.error(t(error ?? t('common.error.unspecific')));

    if (accountSource === AccountSourceUI.AddThirdParty && !showExtraStep) {
      setShowExtraStep(true);
    } else {
      setAccountSource(AccountSourceUI.None);
      setShowExtraStep(false);
    }
  };

  const handleApiWarning = (warning?: string | null) => {
    if (!warning) {
      return;
    }

    let warningMessage = '';
    if (warning.startsWith('001 ')) {
      warningMessage = t('evaluations.incorrectClientId');
    } else if (warning.startsWith('002 ')) {
      warningMessage = t('evaluations.noAccountsRetrieved');
    } else {
      warningMessage = warning.replace(/^\d+ - /, '');
    }

    handleError(warningMessage);
    setApiWarning(warningMessage);
  };

  const selectAllValidAccounts = (
    manualAccounts: EvaluationAccount[],
    errors: ManualAccountErrorsAtom
  ) => {
    const manualAccountIds = manualAccounts
      .filter((account) => !errors.get(account.externalAccountId)?.length)
      .map((account) => account.externalAccountId);
    setSelManualAccountIds(new Set(manualAccountIds));
  };

  const updateManualAccounts = (
    accounts?: {
      records?: ManualAccountRecord[];
    } | null
  ) => {
    if (accounts?.records) {
      const errors: ManualAccountErrorsAtom = new Map();
      const manualAccounts = accounts.records.reduce<EvaluationAccount[]>(
        (accs, record) => {
          if (record?.account) {
            accs.push(toRunEvaluationAccount(record.account));
            if (record.errors) {
              errors.set(
                record.account.collateralAccountId,
                record.errors ? [...record.errors] : []
              );
            }
          }
          return accs;
        },
        []
      );
      setManualAccounts(manualAccounts);
      setManualAccountErrors(errors);
      selectAllValidAccounts(manualAccounts, errors);
    }
  };

  // Api accounts
  const { isLoading: isAquireLoading, isError: isAquireError } = useQueryFetch(
    useUnpledgedAccountsQuery,
    {
      queryHookOptions: {
        enabled: accountSource === AccountSourceUI.Acquire,
        onSuccess: (data) => {
          const accounts = (data as UnpledgedAccountsQuery).unpledgedAccounts;
          updateManualAccounts(accounts);
          handleApiWarning(accounts?.warning);
        },
        onError: () => {
          handleError();
        },
      },
      queryHookParams: {
        customerId: selCustomer?.id || '',
        systemAccountIds,
      },
      extra: {
        auditReport: {
          activityType: ActivityAuditType.Read,
          customerExternalId: selCustomer?.customerId,
        },
      },
    }
  );

  // Third-party accounts
  const { isLoading: isThirdPartyLoading, isError: isThirdPartyError } = useQueryFetch(
    useUnpledgedAccountsManyCustomersQuery,
    {
      queryHookOptions: {
        enabled: accountSource === AccountSourceUI.AddThirdParty,
        onSuccess: (data) => {
          const accounts = (data as UnpledgedAccountsManyCustomersQuery)
            .unpledgedAccountsManyCustomers;
          updateManualAccounts(accounts);
          handleApiWarning(accounts?.warning);
        },
        onError: () => {
          handleError();
        },
      },
      queryHookParams: {
        customerIds: thirdPartyCustomerIds,
        systemAccountIds,
      },
      extra: {
        auditReport: {
          activityType: ActivityAuditType.Read,
          customerExternalId: selCustomer?.customerId,
        },
      },
    }
  );

  // Csv accounts
  const { handleUpload, isLoadingCsvAccountData, selectFileError, uploadFileError } =
    useCsvFileUpload({
      systemAccountIds: systemAccountIds,
      customerExternalId: selCustomer?.customerId,
      onUploadSuccess: (data) => {
        const accounts = (data as ParseAssetCsvQuery).parseAssetCSV;
        updateManualAccounts(accounts);
      },
      onUploadError: (error) => handleError(error),
      onSelectError: (error) => handleError(error),
    });

  React.useEffect(() => {
    if (accountSource === AccountSourceUI.Import && csvData) {
      handleUpload(csvData);
    }
  }, [accountSource, handleUpload, csvData]);

  return {
    isLoading: isAquireLoading || isLoadingCsvAccountData || isThirdPartyLoading,
    isError:
      isAquireError ||
      selectFileError ||
      uploadFileError ||
      isThirdPartyError ||
      apiWarning,
    handleUpload,
  };
};

export const AddAccountSelector: FC<AddAccountSelectorProps> = (props) => {
  // this component to be used for all type manual accounts (import, acquired, etc)
  // all these accounts should be set and used with manualAccountsAtom
  const { t } = useTranslation();
  const { isLoading, isError } = useInitAllAccountsQueries();
  const manualAccounts = useAtomValue(manualAccountsAtom);
  const manualAccountErrors = useAtomValue(manualAccountErrorsAtom);
  const [selManualAccountIds, setSelManualAccountIds] = useAtom(selManualAccountIdsAtom);

  const accountValidationCount = React.useMemo(() => {
    let invalidAccounts = 0;
    for (const errors in manualAccountErrors.values()) {
      if (errors?.length) {
        invalidAccounts += 1;
      }
    }
    const validAccounts = manualAccounts ? manualAccounts?.length - invalidAccounts : 0;
    return {
      validAccounts,
      invalidAccounts,
    };
  }, [manualAccounts, manualAccountErrors]);

  const handleAddAccountSelection = React.useCallback(
    (checked: boolean, externalAccountId: string) => {
      if (checked && !selManualAccountIds.has(externalAccountId)) {
        selManualAccountIds.add(externalAccountId);
        setSelManualAccountIds(new Set(selManualAccountIds));
      } else {
        selManualAccountIds.delete(externalAccountId);
        setSelManualAccountIds(new Set(selManualAccountIds));
      }
    },
    [selManualAccountIds, setSelManualAccountIds]
  );

  return (
    <div className="p-8 h-80 overflow-auto flex items-start justify-center">
      <QuerySuspense
        error={isError}
        isLoading={isLoading}
        className="w-full flex flex-col gap-4"
        loadingRenderer={() => (
          <LoadingSpinner size="4x" className="mx-auto self-center" />
        )}
      >
        {manualAccounts?.map((account) => (
          <AddAccountItem
            key={account.externalAccountId}
            account={account}
            errors={manualAccountErrors.get(account.externalAccountId) || []}
            onAccountSelect={handleAddAccountSelection}
            selected={selManualAccountIds.has(account.externalAccountId)}
          />
        ))}
        {accountValidationCount.invalidAccounts > 0 && (
          <div className="warning" data-testid="invalid-accounts-warning">
            {t('evaluations.addValidAccountsNote')}
          </div>
        )}
        {!(manualAccounts && manualAccounts.length > 0) && (
          <div className="warning" data-testid="no-accounts-warning">
            {t('evaluations.noAccountsRetrieved')}
          </div>
        )}
      </QuerySuspense>
    </div>
  );
};
