import React from 'react';

import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { Listbox, Transition } from '@headlessui/react';
import clsx from 'clsx';
import { FontIcon } from 'components';
import { useTranslation } from 'react-i18next';

export type KeyValueOption = { key: string; value: string };
export type Option = string | KeyValueOption;
export interface DropdownProps {
  label: string;
  options: Option[];
  errorMessage?: string;
  'data-testid'?: string;
  value: Option | null;
  disabled?: boolean;
  onChange: (value: Option) => void;
  onBlur?: (event: React.FocusEvent) => void;
  onFocus?: (event: React.FocusEvent) => void;
}

export const Dropdown: React.FC<DropdownProps> = ({
  label,
  options,
  onChange,
  onBlur,
  onFocus,
  errorMessage,
  disabled,
  value,
  'data-testid': dataTestId,
}) => {
  const { t } = useTranslation();
  const [shrinkLabel, setShrinkLabel] = React.useState(!!value || false);
  const [hasFocus, setHasFocus] = React.useState(false);

  React.useEffect(() => {
    setShrinkLabel(!!value);
  }, [value, setShrinkLabel]);

  const handleClearClick = (e: React.MouseEvent<SVGSVGElement>) => {
    e.preventDefault();
    onChange('');
  };

  const handleChange = React.useCallback(
    (value: Option) => {
      if (shrinkLabel !== !!value) {
        setShrinkLabel(!!value);
      }
      onChange(value);
    },
    [onChange, shrinkLabel]
  );

  const handleBlur = React.useCallback(
    (event: React.FocusEvent) => {
      setHasFocus(false);
      if (onBlur) {
        onBlur(event);
      }
    },
    [setHasFocus, onBlur]
  );

  const handleFocus = React.useCallback(
    (event: React.FocusEvent) => {
      setHasFocus(true);
      if (onFocus) {
        onFocus(event);
      }
    },
    [setHasFocus, onFocus]
  );

  return (
    <Listbox
      as="div"
      data-testid={dataTestId}
      disabled={disabled}
      className="mt-1 mb-4 inline-block w-full"
      value={value}
      onBlur={handleBlur}
      onFocus={handleFocus}
      onChange={handleChange}
    >
      {({ open }) => (
        <>
          <div
            className={clsx([
              'relative',
              'rounded',
              'border',
              'hover:bg-gray-50',
              'outline-primary-600 outline-2',
              'focus-within:outline',
              errorMessage && !open ? 'border-red-500' : 'border-gray-300',
              disabled ? 'bg-gray-100 border-gray-200' : 'bg-white',
            ])}
          >
            <Listbox.Label
              className={clsx([
                'block text-lg',
                'absolute',
                'origin-top-left',
                'left-4',
                'top-px',
                'transition-all',
                'text-gray-500',
                {
                  'text-primary-600': hasFocus,
                  'text-red-500': errorMessage && !open,
                },
                shrinkLabel
                  ? 'scale-75 translate-y-0 font-semibold'
                  : 'scale-100 translate-y-4',
              ])}
            >
              {label}
            </Listbox.Label>
            <div className="relative">
              <span className="inline-block w-full rounded-md shadow-sm">
                <Listbox.Button
                  data-testid={`${dataTestId}-button`}
                  className={clsx([
                    'cursor-default',
                    'relative',
                    'w-full',
                    'pl-4',
                    'pt-6',
                    'pb-4',
                    'pr-10',
                    'text-left',
                    'transition',
                    'ease-in-out',
                    'duration-150',
                    'text-base',
                    'min-h-[24px]',
                    'focus:outline-none',
                    errorMessage && !open ? 'border-red-500' : 'border-gray-300',
                  ])}
                >
                  <span
                    className={clsx([
                      'block  truncate',
                      !!value ? 'visible' : 'invisible',
                    ])}
                  >
                    {value
                      ? typeof value === 'string'
                        ? value
                        : value.value
                      : t('loanProposal.selectOne')}
                  </span>
                  {!disabled && (
                    <span className="absolute inset-y-0 right-0 flex items-center pr-2">
                      {value ? (
                        <FontIcon
                          className={clsx([
                            'cursor-pointer',
                            'h-4',
                            'w-4',
                            'mr-2',
                            'duration-100',
                          ])}
                          onClick={handleClearClick}
                          icon={faXmark}
                        />
                      ) : (
                        <FontIcon
                          className={clsx([
                            'h-4',
                            'w-4',
                            'mr-2',
                            'duration-100',
                            { '-scale-100': open },
                          ])}
                          icon={faChevronDown}
                        />
                      )}
                    </span>
                  )}
                </Listbox.Button>
              </span>

              <Transition
                show={open}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
                className="absolute translate-y-1 scale-x-105 w-full rounded-md bg-white drop-shadow-lg top-full z-10"
              >
                <Listbox.Options
                  static
                  className="max-h-60 rounded-md py-1 leading-6 shadow-md overflow-auto focus:outline-none text-base"
                >
                  {options?.map((option) => (
                    <Listbox.Option
                      key={typeof option === 'string' ? option : option.key}
                      value={option}
                    >
                      {({ selected, active }) => (
                        <div
                          className={clsx([
                            active ? ' bg-gray-50' : 'text-gray-900',
                            selected ? ' bg-gray-100' : '',
                            'cursor-default select-none relative py-2 pl-8 pr-4',
                          ])}
                        >
                          <span
                            className={clsx(
                              selected ? 'font-semibold' : 'font-normal',
                              'block truncate'
                            )}
                          >
                            {typeof option === 'string' ? option : option.value}
                          </span>
                        </div>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
          {!!errorMessage && !open && (
            <div role="alert" className="text-red-500 mt-1 h-5 text-sm">
              {errorMessage}
            </div>
          )}
        </>
      )}
    </Listbox>
  );
};
