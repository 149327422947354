import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';

export const DATE_FORMAT_MONTH_NAME = 'MMMM D, YYYY';
export const DATE_FORMAT_MONTH_NAME_TIME = 'MMMM D, YYYY HH:mm:ss';
export const DATE_FORMAT = 'MM/DD/YYYY';
export const DATE_FORMAT_TIME = 'MM/DD/YYYY HH:mm:ss';
export const DATE_FORMAT_DASH_SEPARATED = 'YYYY-MM-DDTHH:mm:ss.ms';
export const DATE_FORMAT_UNDERSCORE = 'DD_MM_YYYY';
export const TIME_FORMAT_WITH_MS_12H = 'hh:mm:ss a';
export const TIME_FORMAT_12H = 'hh:mm a';

dayjs.extend(utc);

export const formatDate = (dateStr: string, format: string): string =>
  dayjs(new Date(dateStr)).local().format(format);

export const formatDateWithoutTimezone = (dateStr: string, format: string): string =>
  dayjs.utc(new Date(dateStr)).format(format);
