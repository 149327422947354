import React from 'react';

import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import clsx from 'clsx';
import { Button, FontIcon } from 'components';
import { useTranslation } from 'react-i18next';

import { getPageRange } from './pageRange';

type PaginationProps = {
  pageRange: number;
  totalRows: number;
  rowsPerPage: number;
  currentPage: number;
  className?: string;
  previousPage: () => void;
  nextPage: () => void;
  onChange: (page: number) => void;
};

export const Pagination: React.FC<PaginationProps> = ({
  totalRows,
  rowsPerPage,
  currentPage,
  pageRange,
  className,
  previousPage,
  nextPage,
  onChange,
}) => {
  const { t } = useTranslation();
  const [pageFirstRecord, setPageFirstRecord] = React.useState(1);
  const [pageLastRecord, setPageLastRecord] = React.useState(rowsPerPage);
  const pageCount = Math.ceil(totalRows / rowsPerPage);

  React.useEffect(() => {
    const skipFactor = (currentPage - 1) * rowsPerPage;
    setPageFirstRecord(skipFactor + 1);
  }, [pageCount, currentPage, rowsPerPage]);

  React.useEffect(() => {
    const count = pageFirstRecord + rowsPerPage;
    setPageLastRecord(count > totalRows ? totalRows : count - 1);
  }, [pageFirstRecord, rowsPerPage, totalRows]);

  const isFirstPage = currentPage === 1;
  const isLastPage = currentPage === Math.ceil(totalRows / rowsPerPage);

  return (
    <>
      {pageCount > 1 ? (
        <div
          className={clsx(
            className,
            'select-none',
            'text-right',
            'flex',
            'justify-between',
            'items-baseline'
          )}
        >
          <div
            className={clsx(
              'text-gray-500',
              'text-fontSize-2xs',
              'leading-4',
              'fontFamily-nav'
            )}
            data-testid="page-range"
          >
            {t('common.pagination.showEntriesFromTo', {
              first: pageFirstRecord,
              last: pageLastRecord,
              total: totalRows,
            })}
          </div>
          <div>
            <FontIcon
              aria-label="<"
              size="sm"
              onClick={previousPage}
              testid="left-angle"
              icon={faAngleLeft}
              disabled={isFirstPage}
              className={clsx(isFirstPage ? 'text-gray-200' : 'text-primary-600', {
                'mr-4': pageRange > 0,
              })}
            />
            {getPageRange(totalRows, pageRange, rowsPerPage, currentPage)?.map(
              (page, index) => {
                const isActive = page === currentPage;
                return (
                  <Button
                    className={clsx(
                      'w-8',
                      'flex',
                      'justify-center',
                      'py-1',
                      { 'border bg-gray-100 text-primary-600': isActive },
                      { 'text-gray-500': !isActive }
                    )}
                    state="text"
                    onClick={() => onChange(page)}
                    key={index}
                    data-testid={`page${index + 1}`}
                  >
                    {page}
                  </Button>
                );
              }
            )}
            <FontIcon
              aria-label=">"
              size="sm"
              onClick={nextPage}
              testid="right-angle"
              icon={faAngleRight}
              disabled={isLastPage}
              className={clsx('ml-4', isLastPage ? 'text-gray-200' : 'text-primary-600')}
            />
          </div>
        </div>
      ) : null}
    </>
  );
};
