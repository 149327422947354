import React from 'react';

import { createColumnHelper } from '@tanstack/react-table';
import clsx from 'clsx';
import {
  Button,
  Dropdown,
  Ellipsis,
  HelpIcon,
  KeyValueOption,
  Option,
  ReactTable,
} from 'components';
import {
  AccountSource,
  ActivityAuditType,
  EvaluationCollateralAccountPayload,
  useCreditPoliciesQuery,
} from 'generated/graphql';
import { isEqual, sortBy } from 'lodash';
import { useQueryFetch } from 'queries/apiFetch/useQueryFetch';
import { useTranslation } from 'react-i18next';

import { FileUploadButton } from 'components/Button/FileUploadButton';
import { Checkbox, CheckboxOnChange } from 'components/Checkbox/Checkbox';
import { useCustomisation } from 'modules/root/Settings';
import { DEFAULT_CREDITPOLICY } from 'modules/root/Settings/envVars';
import { notEmpty } from 'utils/helpers';
import { printBooleanFull, printMoney, printPrimitive } from 'utils/print';

import { useEvaluationContext } from '../Evaluation';
import { EvaluationActions, UIEvaluationStatus } from '../NewEvaluation';
import { Account, Accounts } from '../types';
import { buildEvaluationCollateralAccounts } from '../utils';
import { AcquiredAccountsModal } from './AcquiredAccountsModal/AcquiredAccountsModal';
import { useCsvFileUpload } from './csvUpload';

const ALL_ACCS = 'all';

interface AccountTableProps {
  collateralAccounts: Account[];
  runEvaluation?: (
    accounts: EvaluationCollateralAccountPayload[],
    creditPolicyId: string
  ) => void;
  evaluationStatus: UIEvaluationStatus;
  viewOnly?: boolean;
  evaluationAction?: EvaluationActions;
  acquireUnpledgedAccounts?: Function;
  evaluatedCreditPolicyId?: string;
  addImportCsvAccounts?: Function;
}

const _isAccValid = (acc: Account): boolean => {
  return !acc?.ineligible;
};

const _getValidAccIds = (statusIsValid: boolean) => {
  return (accs: Accounts): string[] => {
    return (
      accs?.reduce<string[]>((results, collAcc) => {
        if (statusIsValid && _isAccValid(collAcc) && collAcc?.id) {
          results.push(collAcc.id);
        }
        return results;
      }, []) || []
    );
  };
};

const canBeSelected = (acc: Account, viewOnly: boolean, statusIsValid: boolean) =>
  !viewOnly && statusIsValid && _isAccValid(acc);

const getCreditPolicyFromId = (
  key: string,
  creditPolicyList: (KeyValueOption | null)[]
) => {
  if (key && creditPolicyList) {
    const filteredPolicy = creditPolicyList.filter(
      (policy) => !!policy && policy.key === key
    );
    return filteredPolicy.length ? filteredPolicy[0] : null;
  }
  return null;
};

export const AccountTable: React.FC<AccountTableProps> = ({
  collateralAccounts,
  runEvaluation,
  evaluationStatus,
  viewOnly = false,
  evaluationAction,
  acquireUnpledgedAccounts,
  evaluatedCreditPolicyId,
  addImportCsvAccounts,
}) => {
  const {
    notification,
    isDataIssue,
    isNoEligible,
    accountList,
    accountsNotify,
    clearAccountsNotifications,
  } = useEvaluationContext();
  const customer = accountList.data?.customer ?? null;
  const statusIsValid = !isDataIssue && !isNoEligible;
  const { t } = useTranslation();

  const systemAccountIds: string[] = React.useMemo(() => {
    const systemAccounts = collateralAccounts.filter(
      (acc) => acc?.source?.toUpperCase() === 'SYSTEM'
    );
    return systemAccounts.map((acc) => acc?.collateralAccountId).filter(notEmpty);
  }, [collateralAccounts]);

  const { csvAccountData, selectFileError, handleUpload } = useCsvFileUpload({
    systemAccountIds: systemAccountIds,
    customerExternalId: customer?.customerId,
    onSelectError: (messageI18n) => {
      accountsNotify({
        type: 'error',
        dataTestId: 'csv-accounts-error',
        messageI18n,
      });
    },
    onUploadError: (messageI18n) => {
      accountsNotify({
        type: 'error',
        messageI18n: t('common.error.unspecific'),
        dataTestId: 'csv-accounts-error',
      });
    },
  });

  let [showCsvAccountsModal, setShowCsvAccountsModal] = React.useState(false);

  const getValidAccIds = _getValidAccIds(statusIsValid);
  const [selectedAccounts, setSelectedAccounts] = React.useState(
    new Set(getValidAccIds(collateralAccounts))
  );

  React.useEffect(() => {
    const fetchValidAccIds = _getValidAccIds(statusIsValid);
    const validAccIds = fetchValidAccIds(collateralAccounts);
    setSelectedAccounts(new Set(validAccIds));
  }, [collateralAccounts, statusIsValid]);

  const countAccs = React.useMemo(
    () => getValidAccIds(collateralAccounts).length,
    [collateralAccounts, getValidAccIds]
  );

  const { data: creditPolicyData } = useQueryFetch(useCreditPoliciesQuery, {
    extra: {
      auditReport: {
        activityType: ActivityAuditType.Read,
        customerExternalId: customer?.customerId,
      },
    },
  });
  const [accountsProcessed, setAccountsProcessed] = React.useState<(string | null)[]>([]);
  const [creditPolicyProcessed, setcreditPolicyProcessed] = React.useState<string>('');
  const { labels, helpText, creditPolicy, evaluation } = useCustomisation();

  const creditPolicyOptions: KeyValueOption[] | undefined = React.useMemo(() => {
    return creditPolicyData?.creditPolicies.filter(notEmpty).map((policy) => {
      return { key: policy?.id, value: policy?.description.value };
    });
  }, [creditPolicyData]);

  const [selectedCreditPolicy, setSelectedCreditPolicy] =
    React.useState<KeyValueOption | null>(null);

  React.useEffect(() => {
    if (
      creditPolicyOptions &&
      evaluationAction === EvaluationActions.New &&
      DEFAULT_CREDITPOLICY
    ) {
      const filteredPolicy = getCreditPolicyFromId(
        DEFAULT_CREDITPOLICY,
        creditPolicyOptions
      );
      filteredPolicy && setSelectedCreditPolicy(filteredPolicy);
    }
  }, [creditPolicyOptions, evaluationAction]);

  React.useEffect(() => {
    if (
      evaluatedCreditPolicyId &&
      creditPolicyOptions &&
      (evaluationAction === EvaluationActions.ReEvaluate ||
        evaluationAction === EvaluationActions.View)
    ) {
      const filteredPolicy = getCreditPolicyFromId(
        evaluatedCreditPolicyId,
        creditPolicyOptions
      );
      filteredPolicy && setSelectedCreditPolicy(filteredPolicy);
    }
  }, [evaluationAction, evaluatedCreditPolicyId, creditPolicyOptions]);

  const handleChange: CheckboxOnChange = React.useCallback(
    ({ name, value, checked }) => {
      let newValues = new Set(selectedAccounts);
      if (name === ALL_ACCS) {
        if (checked) {
          newValues = new Set(getValidAccIds(collateralAccounts));
        } else {
          newValues = new Set();
        }
      } else if (value) {
        if (checked) {
          newValues.add(value);
        } else {
          newValues.delete(value);
        }
      }
      setSelectedAccounts(newValues);
    },
    [setSelectedAccounts, collateralAccounts, selectedAccounts, getValidAccIds]
  );

  const handleEvaluateBtnCLick = () => {
    clearAccountsNotifications();
    if (runEvaluation && selectedCreditPolicy) {
      const evaluationCollateralAccounts: EvaluationCollateralAccountPayload[] =
        buildEvaluationCollateralAccounts(selectedAccounts, collateralAccounts);

      runEvaluation(evaluationCollateralAccounts, selectedCreditPolicy.key);
      setAccountsProcessed(Array.from(selectedAccounts));
      setcreditPolicyProcessed(selectedCreditPolicy.key);
    }
  };

  React.useEffect(() => {
    const isNoMarketValue =
      collateralAccounts &&
      collateralAccounts.length > 0 &&
      collateralAccounts.some((coll) => !coll?.marketData?.marketValue);
    if (isNoMarketValue && !viewOnly) {
      accountsNotify({
        type: 'info',
        dataTestId: 'need-evaluate-msg',
        messageI18n: 'evaluations.accountList.needEvaluate',
      });
    }
  }, [accountsNotify, collateralAccounts, viewOnly]);

  React.useEffect(() => {
    if (systemAccountIds.length === 0 && collateralAccounts.length === 0 && !viewOnly) {
      accountsNotify({
        type: 'warning',
        dataTestId: 'no-accounts-msg',
        messageI18n: 'evaluations.accountList.noAccounts',
      });
    }
  }, [
    accountsNotify,
    collateralAccounts,
    isDataIssue,
    isNoEligible,
    systemAccountIds,
    viewOnly,
  ]);

  const isProcessedAccounts = React.useMemo(() => {
    return (
      evaluationStatus !== UIEvaluationStatus.Idle &&
      isEqual(sortBy(Array.from(selectedAccounts)), sortBy(accountsProcessed)) &&
      creditPolicyProcessed === selectedCreditPolicy?.key
    );
  }, [
    accountsProcessed,
    creditPolicyProcessed,
    evaluationStatus,
    selectedAccounts,
    selectedCreditPolicy?.key,
  ]);
  const isEvaluationDisabled = React.useMemo(() => {
    return isDataIssue || isNoEligible || isProcessedAccounts;
  }, [isDataIssue, isNoEligible, isProcessedAccounts]);

  const handleAcquireUnpledgedAccountsBtnClick = () => {
    clearAccountsNotifications();

    acquireUnpledgedAccounts && acquireUnpledgedAccounts();
  };

  const columnHelper = createColumnHelper<NonNullable<Account>>();

  const columns = React.useMemo(() => {
    const headerCellsFromConfig = [];
    headerCellsFromConfig.push(
      columnHelper.accessor('id', {
        header: () => (
          <div className="min-w-[20px]">
            {viewOnly ? null : (
              <Checkbox
                data-testid="chck-select-all"
                indeterminate={
                  selectedAccounts.size > 0 && selectedAccounts.size < countAccs
                }
                disabled={countAccs === 0 || viewOnly}
                name={ALL_ACCS}
                checked={countAccs > 0 && countAccs === selectedAccounts.size}
                onChange={handleChange}
              />
            )}
          </div>
        ),
        cell: ({ getValue, row }) => (
          <div>
            {viewOnly ? null : (
              <Checkbox
                data-testid="chck-select"
                name={`account-${getValue()}`}
                value={getValue()}
                checked={selectedAccounts.has(getValue() || '')}
                onChange={handleChange}
                disabled={
                  row.original.source === AccountSource.System &&
                  !canBeSelected(row.original, viewOnly, statusIsValid)
                }
              />
            )}
          </div>
        ),
      })
    );

    headerCellsFromConfig.push(
      columnHelper.accessor('collateralAccountId', {
        header: () => (
          <div className="flex flex-nowrap items-center gap-1">
            {labels.collateralAccountNumber}
            <HelpIcon text={helpText.collateralAccountNumber} />
          </div>
        ),
        cell: ({ getValue }) => <Ellipsis>{printPrimitive(getValue())}</Ellipsis>,
      })
    );

    headerCellsFromConfig.push(
      columnHelper.accessor('accountType', {
        header: () => (
          <div className="flex flex-nowrap items-center gap-1">
            {labels.accountType} <HelpIcon text={helpText.accountType} />
          </div>
        ),
        cell: ({ getValue }) => <Ellipsis>{printPrimitive(getValue())}</Ellipsis>,
      })
    );

    headerCellsFromConfig.push(
      columnHelper.accessor('custodian', {
        header: () => (
          <div className="flex flex-nowrap items-center gap-1">
            {labels.custodian} <HelpIcon text={helpText.custodian} />
          </div>
        ),
        cell: ({ getValue }) => <Ellipsis>{printPrimitive(getValue())}</Ellipsis>,
      })
    );

    headerCellsFromConfig.push(
      columnHelper.accessor('marketData', {
        header: () => (
          <div className="flex flex-nowrap items-center gap-1">
            {labels.marketValue} <HelpIcon text={helpText.marketValue} />
          </div>
        ),
        cell: ({ getValue }) => printMoney(getValue()?.marketValue),
      })
    );

    headerCellsFromConfig.push(
      columnHelper.accessor('ownerDisplayName', {
        header: () => (
          <div className="flex flex-nowrap items-center gap-1">
            {labels.owner} <HelpIcon text={helpText.owner} />
          </div>
        ),
        cell: ({ getValue }) => (
          <Ellipsis data-testid="owner-name" className="max-w-[100px]">
            {printPrimitive(getValue())}
          </Ellipsis>
        ),
      })
    );

    headerCellsFromConfig.push(
      columnHelper.accessor('pledged', {
        header: () => (
          <div className="flex flex-nowrap items-center gap-1">{labels.pledged}</div>
        ),
        cell: ({ getValue }) => printBooleanFull(getValue()),
      })
    );

    headerCellsFromConfig.push(
      columnHelper.accessor('source', {
        header: () => (
          <div className="flex flex-nowrap items-center gap-1">{labels.source}</div>
        ),
        cell: ({ getValue }) => printPrimitive(getValue()),
      })
    );

    return headerCellsFromConfig;
  }, [
    labels,
    columnHelper,
    helpText,
    selectedAccounts,
    countAccs,
    viewOnly,
    statusIsValid,
    handleChange,
  ]);

  const handleCreditPolicyChange = React.useCallback(
    (value: Option) => {
      clearAccountsNotifications();
      if (typeof value !== 'string') {
        setSelectedCreditPolicy(value);
      }
    },
    [clearAccountsNotifications]
  );

  const handleUploadBtnClick = React.useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      clearAccountsNotifications();
      handleUpload(e);
      setShowCsvAccountsModal(true);
    },
    [clearAccountsNotifications, handleUpload]
  );

  const handleClose = React.useCallback(() => {
    setShowCsvAccountsModal(false);
    addImportCsvAccounts && addImportCsvAccounts([]);
  }, [addImportCsvAccounts]);

  const handleAdd = React.useCallback(() => {
    setShowCsvAccountsModal(false);
    const validAccounts =
      csvAccountData?.parseAssetCSV &&
      csvAccountData?.parseAssetCSV.records.filter((acc) => acc.errors?.length === 0);

    const csvAccountsWithSource: Account[] =
      validAccounts
        ?.map(
          (acc) =>
            acc.account && {
              ...acc.account,
              assetHoldings: acc.account?.assetHoldings
                ? acc.account?.assetHoldings.filter(notEmpty)
                : [],
            }
        )
        .filter(notEmpty) || [];
    setSelectedAccounts((accs) => {
      csvAccountsWithSource.forEach((acc) => acc && accs.add(acc.id));
      return accs;
    });
    if (csvAccountsWithSource && csvAccountsWithSource.length > 0) {
      addImportCsvAccounts && addImportCsvAccounts(csvAccountsWithSource);
    }
  }, [csvAccountData?.parseAssetCSV, addImportCsvAccounts]);

  return (
    <div className="flex flex-col gap-4">
      <ReactTable<NonNullable<Account>>
        data-testid="accounts-table"
        data={collateralAccounts.filter(notEmpty) || []}
        className="max-w-[calc(100vw-198px)] lg:max-w-[calc(100vw-248px)] xl:max-w-full overflow-x-auto"
        columns={columns}
      />

      {notification.accounts && !viewOnly && (
        <div
          data-testid={notification.accounts.dataTestId}
          className={clsx('ml-4', {
            alert: notification.accounts.type === 'error',
            notification: notification.accounts.type === 'info',
            warning: notification.accounts.type === 'warning',
          })}
        >
          {t(notification.accounts.messageI18n)}
        </div>
      )}

      {creditPolicyOptions && (
        <div className="ml-4 w-96">
          <Dropdown
            label={'Credit Policy'}
            data-testid={'credit-policy-dropdown'}
            disabled={viewOnly || !creditPolicy.allowCreditPolicySelection}
            options={creditPolicyOptions}
            onChange={handleCreditPolicyChange}
            value={selectedCreditPolicy}
          />
        </div>
      )}

      {!viewOnly && (
        <div className="ml-4 flex gap-3">
          <Button
            disabled={
              isEvaluationDisabled || selectedAccounts.size === 0 || !selectedCreditPolicy
            }
            onClick={handleEvaluateBtnCLick}
            data-testid="evaluate-button"
          >
            {evaluationAction && evaluationAction === EvaluationActions.ReEvaluate
              ? t('evaluations.reEvaluate')
              : t('evaluations.evaluate')}
          </Button>
          {evaluation.showButtons.importAccountWithHoldings &&
            evaluationAction === EvaluationActions.New && (
              <FileUploadButton
                label={labels.importAccountWithHoldings}
                onChange={handleUploadBtnClick}
                disabled={isEvaluationDisabled}
                data-testid="import-CSV-button"
                fileType=".csv"
                error={selectFileError}
              />
            )}
          {evaluation.showButtons.acquireUnpledgedAccounts &&
            acquireUnpledgedAccounts &&
            evaluationAction === EvaluationActions.New && (
              <Button
                onClick={handleAcquireUnpledgedAccountsBtnClick}
                data-testid="acquire-unpledged-accounts-button"
                disabled={isEvaluationDisabled}
              >
                {labels.acquireUnpledgedAccounts}
              </Button>
            )}
        </div>
      )}
      {showCsvAccountsModal && csvAccountData?.parseAssetCSV && (
        <AcquiredAccountsModal
          accounts={csvAccountData?.parseAssetCSV.records}
          onClose={handleClose}
          onAdd={handleAdd}
        />
      )}
    </div>
  );
};
